import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import arrayMove from 'array-move'

import './SurveyForm.scss'
import {saveCourse} from '../../redux/courses'
import withSortableList from '../decorators/withSortableList'
import TextField from '../fields/TextField'
import ExpansionBlock from '../common/ExpansionBlock'
import BlockButton from '../common/BlockButton'
import InspectLink from '../common/InspectLink'
import DragElement from '../common/DragElement'
import AddButton from '../common/AddButton'
import DeleteButton from '../common/DeleteButton'
import Submit from '../common/Submit'
import {
  getName,
  getTicketData,
  normalizeSortOrder,
  getInitialSection,
  getInitialPage,
  checkOriginal,
} from '../../utils/helpers'

SurveyForm.propTypes = {
  currentPath: PropTypes.string.isRequired,
  survey: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  saveCourse: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
}

const SortablePage = SortableElement(
  ({uuid, title, original, currentPath, sectionId, onDelete}) => {
    const handeDelete = () => {
      onDelete(uuid)
    }

    return (
      <li key={uuid} className="Block Block_small Block_empty">
        <div className="FlexCenter FlexCenter_indent">
          <DragElement />
          <p className="TextLarge">{title}</p>
        </div>
        {
          <InspectLink
            to={`${currentPath}/${sectionId}/${uuid}`}
            disabled={!original}
          />
        }
        {onDelete && <DeleteButton onClick={handeDelete} />}
      </li>
    )
  }
)

const SortablePages = SortableContainer(
  ({pages, originalPages, currentPath, sectionId, onAdd, onDelete}) => (
    <ul className="BlocksGroup BlocksGroup_topIndent">
      {pages.map((page, index) => (
        <SortablePage
          key={page.uuid}
          {...page}
          index={index}
          original={checkOriginal(originalPages, page.uuid)}
          currentPath={currentPath}
          sectionId={sectionId}
          onDelete={pages.length > 1 ? onDelete : undefined}
        />
      ))}
      <li className="Block Block_small Block_empty Block_simple">
        <AddButton label="page" onClick={onAdd} />
      </li>
    </ul>
  )
)

const SortableSection = SortableElement(
  ({
    uuid,
    sortOrder,
    pages,
    originalSections,
    currentPath,
    notLast,
    sections,
    setSections,
    onDelete,
  }) => {
    const handeSectionDelete = () => {
      onDelete(uuid)
    }

    const handlePageSortEnd = ({oldIndex, newIndex}) => {
      const newSections = cloneDeep(sections)
      const movedPages = normalizeSortOrder(
        arrayMove(pages, oldIndex, newIndex)
      )
      newSections.find((section) => section.uuid === uuid).pages = movedPages
      setSections(newSections)
    }

    const handlePageAdd = () => {
      const newSections = cloneDeep(sections)
      const currentSection = newSections.find(
        (section) => section.uuid === uuid
      )
      currentSection.pages.push(getInitialPage(pages.length + 1))
      setSections(newSections)
    }

    const handlePageDelete = (id) => {
      const newSections = cloneDeep(sections)
      const currentSection = newSections.find(
        (section) => section.uuid === uuid
      )
      currentSection.pages = normalizeSortOrder(
        currentSection.pages.filter((page) => page.uuid !== id)
      )
      setSections(newSections)
    }

    const originalPages = originalSections.find(
      (section) => section.uuid === uuid
    )?.pages
    return (
      <ExpansionBlock
        title={`Section ${sortOrder}`}
        onDelete={notLast ? handeSectionDelete : undefined}
      >
        <TextField
          required
          name={getName(uuid, 'sectionTitle')}
          label="Section title"
        />
        <TextField
          required
          multiline
          name={getName(uuid, 'sectionDescription')}
          label="Description"
          rows={6}
        />
        <SortablePages
          useDragHandle
          pages={pages}
          originalPages={originalPages}
          currentPath={currentPath}
          sectionId={uuid}
          onSortEnd={handlePageSortEnd}
          onAdd={handlePageAdd}
          onDelete={handlePageDelete}
        />
      </ExpansionBlock>
    )
  }
)

const SortableSections = SortableContainer(
  ({sections, currentPath, originalSections, setSections, onDelete}) => (
    <ul className="SurveyForm-List">
      {sections.map((section, index) => (
        <SortableSection
          key={section.uuid}
          {...section}
          index={index}
          originalSections={originalSections}
          currentPath={currentPath}
          notLast={sections.length > 1}
          sections={sections}
          setSections={setSections}
          onDelete={onDelete}
        />
      ))}
    </ul>
  )
)

function SurveyForm({
  currentPath,
  survey,
  course,
  courseSaveLoading,
  items,
  saveCourse,
  setItems,
  onSortEnd,
  onItemAdd,
  onItemDelete,
}) {
  const handleSubmit = (values) => {
    const ticket = getTicketData(course)
    const currentPart = ticket.surveyData.parts.find(
      (part) => part.uuid === survey.uuid
    )
    currentPart.title = values.title
    currentPart.subtitle = values.subtitle
    currentPart.description = values.description
    currentPart.thankYouPageContent = values.thankYouPageContent
    const newSections = cloneDeep(items)
    newSections.forEach((section) => {
      section.title = values[getName(section.uuid, 'sectionTitle')]
      section.description = values[getName(section.uuid, 'sectionDescription')]
    })
    currentPart.sections = newSections
    setItems(newSections)
    return saveCourse(ticket)
  }

  const renderForm = ({pristine, handleSubmit}) => (
    <form className="SurveyForm" autoComplete="off" onSubmit={handleSubmit}>
      <TextField required name="title" label="Title" />
      <TextField required name="subtitle" label="Subtitle" />
      <TextField
        multiline
        required
        name="description"
        label="Description"
        rows={8}
      />
      <section className="SurveyForm-Sections">
        <SortableSections
          useDragHandle
          sections={items}
          originalSections={survey.sections}
          currentPath={currentPath}
          setSections={setItems}
          onSortEnd={onSortEnd}
          onDelete={onItemDelete}
        />
        <BlockButton
          label={`section ${items.length + 1}`}
          onClick={onItemAdd}
        />
      </section>
      <p className="ColorGrey">
        After comleting whole part user will have Thank you Page.
        <br />
        This page contains: feedback section and section woth link to personal
        test (16 personalities).
        <br />
        Please provide rest of the text here:
      </p>
      <TextField
        multiline
        required
        name="thankYouPageContent"
        placeholder="Write here…"
        rows={8}
      />
      <Submit
        pristine={pristine && isEqual(items, survey.sections)}
        loading={courseSaveLoading}
      />
    </form>
  )

  const {title, subtitle, description, thankYouPageContent} = survey
  const initialValues = {
    title,
    subtitle,
    description,
    thankYouPageContent,
  }
  survey.sections.forEach(({uuid, title, description}) => {
    initialValues[getName(uuid, 'sectionTitle')] = title
    initialValues[getName(uuid, 'sectionDescription')] = description
  })
  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSortableList(SurveyForm, ['survey', 'sections'], getInitialSection))
