import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'

import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg'

InspectLink.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default function InspectLink({to, ...props}) {
  return (
    <IconButton
      {...props}
      component={Link}
      className="Action"
      to={to}
      aria-label="Inspect"
    >
      <ArrowIcon />
    </IconButton>
  )
}
