import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import InstructionQuestionForm from './InstructionQuestionForm'
import {ROUTE, COURSE_TYPE} from '../../utils/constants'

InstructionQuestion.propTypes = {
  match: PropTypes.object.isRequired,
  courseTitle: PropTypes.string.isRequired,
  videoSurveyTitle: PropTypes.string.isRequired,
  questionsLength: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  courseLoading: PropTypes.bool.isRequired,
}

function InstructionQuestion({
  match,
  courseTitle,
  videoSurveyTitle,
  questionsLength,
  question,
  courseLoading,
}) {
  const links = [
    {to: ROUTE.courses, label: 'Courses'},
    {to: `${ROUTE.courses}/${match.params.id}`, label: courseTitle},
    {
      to: `${ROUTE.courses}/${match.params.id}/${COURSE_TYPE.instruction}`,
      label: `Instructions ‘${videoSurveyTitle}’`,
    },
  ]
  return (
    <>
      <Breadcrumbs
        links={links}
        title={`Step ${question.sortOrder} ${question.title}`}
      />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent">
          Step {question.sortOrder}{' '}
          <span className="ColorGrey">/ {questionsLength}</span>
        </h1>
        <InstructionQuestionForm {...question} />
      </div>
    </>
  )
}

const mapStateToProps = (state, {match}) => ({
  courseTitle: state.courses.course.title,
  videoSurveyTitle: state.courses.course.videoSurvey.title,
  questionsLength: state.courses.course.videoSurvey.questions.length,
  question: state.courses.course.videoSurvey.questions.find(
    (question) => question.uuid === match.params.questionId
  ),
  courseLoading: state.courses.courseLoading,
})

export default connect(mapStateToProps)(InstructionQuestion)
