import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import {addCompany} from '../../redux/companies'
import withDialog from '../decorators/withDialog'
import TextField from '../fields/TextField'
import ImageField from '../fields/ImageField'
import Submit from '../common/Submit'
import CloseButton from '../common/CloseButton'

CompanyAdd.propTypes = {
  companyAddLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  addCompany: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const title = 'Create company'

const DialogContent = ({companyAddLoading, addCompany, onClose}) => {
  const handleSubmit = (values) => addCompany(values, onClose)

  const renderForm = ({handleSubmit}) => (
    <form className="CompanyAdd" autoComplete="off" onSubmit={handleSubmit}>
      <ImageField required name="logo" />
      <TextField required name="fullTitle" label="Title" />
      <TextField required name="title" label="Abbreviation" />
      <TextField name="contactName" label="Contact name" />
      <TextField name="contactEmail" type="email" label="Contact email" />
      <Submit loading={companyAddLoading} />
    </form>
  )

  return (
    <>
      <CloseButton onClick={onClose} disabled={companyAddLoading} />
      <h2 className="Title Title_indent">{title}</h2>
      <Form render={renderForm} onSubmit={handleSubmit} />
    </>
  )
}

function CompanyAdd({companyAddLoading, open, onOpen, onClose, ...props}) {
  return (
    <>
      <Button onClick={onOpen}>{title}</Button>
      <Dialog open={open} onClose={companyAddLoading ? undefined : onClose}>
        <DialogContent
          {...props}
          companyAddLoading={companyAddLoading}
          onClose={onClose}
        />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  companyAddLoading: state.companies.companyAddLoading,
})

const mapDispatchToProps = {addCompany}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(CompanyAdd))
