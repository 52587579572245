import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import './CoursePart.scss'
import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import CourseForm from './CourseForm'
import {ROUTE, PAGE_PART} from '../../utils/constants'

CoursePart.propTypes = {
  match: PropTypes.object.isRequired,
  courseTitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  courseLoading: PropTypes.bool.isRequired,
}

function CoursePart({match, courseTitle, content, courseLoading}) {
  const {type} = match.params
  const links = [
    {to: ROUTE.courses, label: 'Courses'},
    {
      to: `${ROUTE.courses}/${match.params.id}`,
      label: courseTitle,
    },
  ]
  const isWelcome = type === PAGE_PART.welcome
  const title = isWelcome ? 'Welcome' : 'Conclusion'
  return (
    <div className="CoursePart">
      <Breadcrumbs links={links} title={title} />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent">{title}</h1>
        <p className="CoursePart-Text">
          {isWelcome
            ? 'Fisrt page with welcome text. After the text user will provide name, city/town, email, phone number and upload personal photo.'
            : 'Last page with final text.'}
        </p>
        <p className="CoursePart-Text">Provide text here:</p>
        <CourseForm type={type} content={content} />
      </div>
    </div>
  )
}

const mapStateToProps = (state, {match}) => ({
  courseTitle: state.courses.course.title,
  content: state.courses.course[`${match.params.type}Content`],
  courseLoading: state.courses.courseLoading,
})

export default connect(mapStateToProps)(CoursePart)
