import React from 'react'
import PropTypes from 'prop-types'
import {v4 as generateId} from 'uuid'
import cloneDeep from 'lodash.clonedeep'

import './Answer.scss'
import DeleteButton from '../common/DeleteButton'
import AddButton from '../common/AddButton'
import TextField from '../fields/TextField'
import CheckboxField from '../fields/CheckboxField'
import Options from './Options'
import {getName} from '../../utils/helpers'
import {
  TYPE,
  PREDEFINED_GROUP,
  INDUSTRIES,
  LANGUAGES,
  MAX_STEPS,
} from '../../utils/constants'

Answer.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  parameters: PropTypes.array,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
}

const predefinedContentMap = {
  [TYPE.multiSelectboxIndustries]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: INDUSTRIES,
  },
  [TYPE.speakLanguages]: {
    title: 'Language list',
    description: `select language and slider for level of knowledge (${LANGUAGES.length} predefined languages)`,
    items: [
      'Level 1 – Started learning',
      'Level 2 – A beginner',
      'Level 3 – Intermediate level',
      'Level 4 – Advanced level',
      'Level 5 – Native language]',
    ],
  },
  [TYPE.multiSelectboxHobbies]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: [
      'Baking',
      'Cooking',
      'Dancing',
      'Videogames',
      'Fishing',
      'Hiking',
      'Karaoke',
      'Arts',
      'Movies',
      'Books',
      'Music',
      'Podcasts',
      'Photography',
      'Travelling',
      'Fitness',
      'Painting',
      'Volunteering',
      'Languages',
      'Camping',
      'Writing',
      'Sport',
      'Other',
    ],
  },
  [TYPE.multiSelectboxDrivingDisciplines]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: [],
  },
  [TYPE.multiSelectboxMachineOperatorDisciplines]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: [],
  },
  [TYPE.multiSelectboxCombinationVehicles]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: [],
  },
  [TYPE.multiSelectboxGearboxes]: {
    title: 'Check list',
    description: 'checkboxes for multiple choice',
    items: [],
  },
  [TYPE.selectboxIndustries]: {
    title: 'Radio list',
    description: 'radio buttons for single choice',
    items: INDUSTRIES,
  },
  [TYPE.selectboxLanguages]: {
    title: 'Radio list',
    description: 'radio buttons for single choice',
    items: LANGUAGES,
  },
  [TYPE.employmentGrid]: {
    title: 'List of 5 lines with fileds',
    items: ['Name of workplace / company', 'Your role', 'Time in position'],
  },
  [TYPE.citySelect]: {
    title: 'Group of 3 fileds',
    items: ['Suburb / area', 'City / Town', 'Region'],
  },
  [TYPE.drivingLicenseClasses]: {
    title: 'Driving license classes',
    items: [],
  },
  [TYPE.drivingLicenseEndorsements]: {
    title: 'Driving license endorsements',
    items: [],
  },
}

const Step = ({uuid, title, questionId, notLast, questions, setQuestions}) => {
  const handleDelete = () => {
    const newQuestions = cloneDeep(questions)
    const currentQuestion = newQuestions.find(
      (question) => question.uuid === questionId
    )
    currentQuestion.parameters = currentQuestion.parameters
      .filter((parameter) => parameter.uuid !== uuid)
      .map((step, index) => ({
        ...step,
        value: index + 1,
        title: `Level ${index + 1}`,
      }))
    setQuestions(newQuestions)
  }

  return (
    <div className="Relative">
      <TextField
        required
        name={getName(questionId, uuid, 'description')}
        label={title}
      />
      {notLast && <DeleteButton option onClick={handleDelete} />}
    </div>
  )
}

export default function Answer({
  id,
  type,
  parameters,
  questions,
  setQuestions,
}) {
  if (type === TYPE.text || type === TYPE.string)
    return (
      <>
        {type === TYPE.string && (
          <CheckboxField name={getName(id, 'multiple')} label="Multiple" />
        )}
        <TextField
          required
          name={getName(id, 'placeholder')}
          label="Placeholder"
        />
      </>
    )

  if (type === TYPE.checkbox || type === TYPE.radio) {
    return (
      <Options
        questionId={id}
        type={type}
        parameters={parameters}
        questions={questions}
        setQuestions={setQuestions}
      />
    )
  }

  if (type === TYPE.range) {
    const handleStepAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentQuestion = newQuestions.find(
        (question) => question.uuid === id
      )
      const {parameters} = currentQuestion
      const value = parameters[parameters.length - 1].value + 1
      currentQuestion.parameters.push({
        uuid: generateId(),
        value,
        title: `Level ${value}`,
        description: null,
      })
      setQuestions(newQuestions)
    }

    return (
      <>
        {parameters.map((parameter) => (
          <Step
            key={parameter.uuid}
            {...parameter}
            questionId={id}
            notLast={parameters.length > 1}
            questions={questions}
            setQuestions={setQuestions}
          />
        ))}
        {parameters.length < MAX_STEPS && (
          <AddButton label="level" onClick={handleStepAdd} />
        )}
      </>
    )
  }

  if (PREDEFINED_GROUP.options.some((option) => option.value === type)) {
    const content = predefinedContentMap[type]
    return (
      <div className="Answer Answer_predefined">
        <p className="Answer-PredefinedTitle">
          <span className="TextSemiBold">{content.title}</span>
          {content.description && ` - ${content.description}`}:
        </p>
        <ul>
          {content.items.map((item, index) => (
            <li key={index} className="Answer-PredefinedItem">
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return null
}
