import React from 'react'
import PropTypes from 'prop-types'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {v4 as generateId} from 'uuid'
import cloneDeep from 'lodash.clonedeep'
import arrayMove from 'array-move'
import IconButton from '@material-ui/core/IconButton'

import './Options.scss'
import {ReactComponent as AlignIcon} from '../../assets/icons/align.svg'
import {ReactComponent as SliderIcon} from '../../assets/icons/slider.svg'
import {ReactComponent as QuestionIcon} from '../../assets/icons/question.svg'
import DeleteButton from '../common/DeleteButton'
import AddButton from '../common/AddButton'
import DragElement from '../common/DragElement'
import TextField from '../fields/TextField'
import {
  getName,
  getInitialQuestion,
  getInitialParameter,
  getInitialStep, getInitialSelectboxValue,
} from '../../utils/helpers'
import {TYPE, MAX_STEPS} from '../../utils/constants'

Options.propTypes = {
  questionId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  parameters: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
}

const maxHiddenQuestions = 2

const getInitialExplanationValue = () => ({
  uuid: generateId(),
  type: TYPE.string,
  placeholder: null,
})

const HiddenQuestions = ({
  questionId,
  parameterId,
  hiddenQuestions,
  questions,
  setQuestions,
}) => {
  const handleAdd = () => {
    const newQuestions = cloneDeep(questions)
    const currentParameter = newQuestions
      .find((question) => question.uuid === questionId)
      .parameters.find((parameter) => parameter.uuid === parameterId)
    currentParameter.hideQuestions.push(getInitialQuestion())
    setQuestions(newQuestions)
  }

  const handleDelete = (id) => () => {
    const newQuestions = cloneDeep(questions)
    const currentParameter = newQuestions
      .find((question) => question.uuid === questionId)
      .parameters.find((parameter) => parameter.uuid === parameterId)
    if (currentParameter.hideQuestions.length < maxHiddenQuestions) {
      currentParameter.hideQuestions = null
    } else {
      currentParameter.hideQuestions = currentParameter.hideQuestions.filter(
        (hideQuestion) => hideQuestion.uuid !== id
      )
    }

    setQuestions(newQuestions)
  }

  return (
    <div className="Option-Addition">
      {hiddenQuestions.map(({uuid}, index) => (
        <div key={uuid} className="Option-Question">
          <div className="Relative">
            <TextField
              required
              name={getName(questionId, parameterId, uuid, 'title')}
              label={`Question ${index + 1}`}
            />
            <DeleteButton option onClick={handleDelete(uuid)} />
          </div>
          <TextField
            name={getName(questionId, parameterId, uuid, 'subtitle')}
            label="Explanation"
          />
          <TextField
            required
            name={getName(questionId, parameterId, uuid, 'placeholder')}
            label="Placeholder"
          />
        </div>
      ))}
      {hiddenQuestions.length < maxHiddenQuestions && (
        <AddButton small label="one more question" onClick={handleAdd} />
      )}
    </div>
  )
}

const AdditionStep = ({uuid, title, questionId, parameterId, onDelete}) => {
  const handleDelete = () => {
    onDelete(uuid)
  }

  return (
    <div className="Relative">
      <TextField
        required
        name={getName(questionId, parameterId, uuid, 'description')}
        label={title}
      />
      <DeleteButton option onClick={handleDelete} />
    </div>
  )
}

const AdditionExplanation = ({
  single,
  questionId,
  parameterId,
  id,
  label,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete(single ? undefined : id)
  }

  return (
    <div className="Relative">
      <TextField
        required
        name={getName(questionId, parameterId, id, 'placeholder')}
        label={label}
      />
      <DeleteButton option onClick={handleDelete} />
    </div>
  )
}

const SortableOption = SortableElement(
  ({
    uuid,
    additionalValue,
    hideQuestions,
    radio,
    questionId,
    questions,
    setQuestions,
    onDelete,
  }) => {
    const handleExplanationAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.additionalValue = getInitialExplanationValue()
      setQuestions(newQuestions)
    }

    const handleExplanationExtraAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.additionalValue = [
        additionalValue,
        getInitialExplanationValue(),
      ]
      setQuestions(newQuestions)
    }

    const handleRangeAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.additionalValue = {
        type: TYPE.range,
        multiple: false,
        placeholder: null,
        steps: [getInitialStep(1), getInitialStep(2)],
      }
      setQuestions(newQuestions)
    }

    const handleRangeStepAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      const {steps} = currentParameter.additionalValue
      const value = steps[steps.length - 1].value + 1
      currentParameter.additionalValue.steps.push(getInitialStep(value))
      setQuestions(newQuestions)
    }

    const handleSelectboxAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.additionalValue = {
        type: TYPE.selectbox,
        multiple: false,
        placeholder: null,
        values: [getInitialSelectboxValue(''), getInitialSelectboxValue('')],
      }
      setQuestions(newQuestions)
    }

    const handleSelectboxValueAdd = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.additionalValue.values.push(getInitialSelectboxValue(''))
      setQuestions(newQuestions)
    }

    const handleExplanationDelete = (extraId) => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      if (extraId) {
        currentParameter.additionalValue = currentParameter.additionalValue.find(
          (item) => item.uuid !== extraId
        )
      } else {
        currentParameter.additionalValue = null
      }
      setQuestions(newQuestions)
    }

    const handleRangeDelete = (stepId) => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      const {steps} = currentParameter.additionalValue
      if (steps.length < 3) {
        currentParameter.additionalValue = null
      } else {
        currentParameter.additionalValue.steps = steps
          .filter((step) => step.uuid !== stepId)
          .map((step, index) => ({
            ...step,
            value: index + 1,
            title: `Level ${index + 1}`,
          }))
      }
      setQuestions(newQuestions)
    }

    const handleSelectboxDelete = (valueId) => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      const {values} = currentParameter.additionalValue
      if (values.length < 3) {
        currentParameter.additionalValue = null
      } else {
        currentParameter.additionalValue.values = values
          .filter((value) => value.uuid !== valueId)
          .map((value, index) => ({...value}))
      }
      setQuestions(newQuestions)
    }

    const handleQuestionsHide = () => {
      const newQuestions = cloneDeep(questions)
      const currentParameter = newQuestions
        .find((question) => question.uuid === questionId)
        .parameters.find((parameter) => parameter.uuid === uuid)
      currentParameter.hideQuestions = [getInitialQuestion()]
      setQuestions(newQuestions)
    }

    const handeDelete = () => {
      onDelete(uuid)
    }

    return (
      <li className="Block Block_option Block_empty">
        <div className="Option-Block">
          <div className="Option-Wrapper FlexCenter">
            <DragElement />
            <div className="Option-Fields">
              <TextField
                required
                native
                className="Option-Input"
                name={getName(questionId, uuid, 'value')}
                label="Title...*"
              />
              <TextField
                native
                className="Option-SubInput"
                name={getName(questionId, uuid, 'description')}
                label="Subtitle..."
              />
            </div>
          </div>
          <div className="Option-Actions">
            {!additionalValue && (
              <>
                <IconButton
                  className="Action"
                  aria-label="Add explanation"
                  onClick={handleExplanationAdd}
                >
                  <AlignIcon />
                </IconButton>
                <IconButton
                  className="Action"
                  aria-label="Add range"
                  onClick={handleRangeAdd}
                >
                  <SliderIcon />
                </IconButton>
                <IconButton
                  className="Action"
                  aria-label="Add selectbox"
                  onClick={handleSelectboxAdd}
                >
                  <SliderIcon />
                </IconButton>
              </>
            )}
            {radio && !hideQuestions && (
              <IconButton
                className="Action"
                aria-label="Hide questions"
                onClick={handleQuestionsHide}
              >
                <QuestionIcon />
              </IconButton>
            )}
          </div>
          {onDelete && <DeleteButton onClick={handeDelete} />}
        </div>
        {additionalValue && (
          <div className="Option-Addition">
            {additionalValue.type === TYPE.range ? (
              <>
                {additionalValue.steps.map((step) => (
                  <AdditionStep
                    key={step.uuid}
                    {...step}
                    questionId={questionId}
                    parameterId={uuid}
                    onDelete={handleRangeDelete}
                  />
                ))}
                {additionalValue.steps.length < MAX_STEPS && (
                  <AddButton
                    small
                    label="another level"
                    onClick={handleRangeStepAdd}
                  />
                )}
              </>
            ) : additionalValue.type === TYPE.selectbox ? (
              <>
                {additionalValue.values.map((value) => (
                  <AdditionStep
                    key={value.uuid}
                    {...value}
                    questionId={questionId}
                    parameterId={uuid}
                    onDelete={handleSelectboxDelete}
                  />
                ))}
                <AddButton
                  small
                  label="another value"
                  onClick={handleSelectboxValueAdd}
                />
              </>
            ) : Array.isArray(additionalValue) ? (
              <>
                {additionalValue.map((item) => (
                  <AdditionExplanation
                    key={item.uuid}
                    questionId={questionId}
                    parameterId={uuid}
                    id={item.uuid}
                    label="Explanation placeholder"
                    onDelete={handleExplanationDelete}
                  />
                ))}
                {additionalValue.length < 2 && (
                  <AddButton
                    small
                    label="another explanation"
                    onClick={handleExplanationExtraAdd}
                  />
                )}
              </>
            ) : (
              <>
                <AdditionExplanation
                  single
                  questionId={questionId}
                  parameterId={uuid}
                  id={additionalValue.uuid}
                  label="Explanation placeholder"
                  onDelete={handleExplanationDelete}
                />
                <AddButton
                  small
                  label="another explanation"
                  onClick={handleExplanationExtraAdd}
                />
              </>
            )}
          </div>
        )}
        {hideQuestions && (
          <HiddenQuestions
            questionId={questionId}
            parameterId={uuid}
            hiddenQuestions={hideQuestions}
            questions={questions}
            setQuestions={setQuestions}
          />
        )}
      </li>
    )
  }
)

const SortableOptions = SortableContainer(
  ({
    questionId,
    type,
    parameters,
    questions,
    setQuestions,
    onAdd,
    onDelete,
  }) => (
    <ul className="BlocksGroup BlocksGroup_topIndent">
      {parameters.map((parameter, index) => (
        <SortableOption
          key={parameter.uuid}
          {...parameter}
          index={index}
          radio={type === TYPE.radio}
          questionId={questionId}
          questions={questions}
          setQuestions={setQuestions}
          onDelete={parameters.length > 1 ? onDelete : undefined}
        />
      ))}
      {parameters.length < 10 && (
        <li className="Block Block_option Block_empty Block_simple">
          <div className="Option-Block">
            <AddButton label="option" onClick={onAdd} />
          </div>
        </li>
      )}
    </ul>
  )
)

export default function Options({
  questionId,
  type,
  parameters,
  questions,
  setQuestions,
}) {
  const handleOptionSortEnd = ({oldIndex, newIndex}) => {
    const newQuestions = cloneDeep(questions)
    const movedParameters = arrayMove(parameters, oldIndex, newIndex)
    newQuestions.find(
      (question) => question.uuid === questionId
    ).parameters = movedParameters
    setQuestions(newQuestions)
  }

  const handleOptionAdd = () => {
    const newQuestions = cloneDeep(questions)
    const currentQuestion = newQuestions.find(
      (question) => question.uuid === questionId
    )
    currentQuestion.parameters.push(getInitialParameter())
    setQuestions(newQuestions)
  }

  const handleOptionDelete = (id) => {
    const newQuestions = cloneDeep(questions)
    const currentQuestion = newQuestions.find(
      (question) => question.uuid === questionId
    )
    currentQuestion.parameters = currentQuestion.parameters.filter(
      (parameter) => parameter.uuid !== id
    )
    setQuestions(newQuestions)
  }

  return (
    <div className="Option">
      <SortableOptions
        useDragHandle
        questionId={questionId}
        type={type}
        parameters={parameters}
        questions={questions}
        setQuestions={setQuestions}
        onSortEnd={handleOptionSortEnd}
        onAdd={handleOptionAdd}
        onDelete={handleOptionDelete}
      />
    </div>
  )
}
