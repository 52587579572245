import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {
  // StrictMode warnings remove
  unstable_createMuiStrictModeTheme as createMuiTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import './styles/index.scss'
import store from './redux'
import App from './components/App'
import ScrollToTop from './components/base/ScrollToTop'
import {unregister as unregisterServiceWorker} from './utils/serviceWorker'
import THEME from './styles/theme'

const theme = createMuiTheme(THEME)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          <App />
        </ThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

unregisterServiceWorker()
