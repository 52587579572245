import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './CheckboxIcon.scss'
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg'

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default function CheckboxIcon({checked, disabled}) {
  return (
    <div
      className={cn('CheckboxIcon', {
        CheckboxIcon_checked: checked,
        CheckboxIcon_disabled: disabled,
      })}
    >
      <CheckIcon className="CheckboxIcon-Svg" />
    </div>
  )
}
