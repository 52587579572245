import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Link} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import MuiLink from '@material-ui/core/Link'

import './Breadcrumbs.scss'
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg'
import {TEXT_PLUG} from '../../utils/constants'

Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  title: PropTypes.string,
  noMargin: PropTypes.bool,
}

export default function Breadcrumbs({links, title = TEXT_PLUG, noMargin}) {
  return (
    <div className={cn('Breadcrumbs', {Breadcrumbs_noMargin: noMargin})}>
      <IconButton
        component={Link}
        className="Breadcrumbs-Back Action"
        to={links[links.length - 1].to}
        aria-label="Back"
      >
        <ArrowIcon className="Breadcrumbs-Icon" />
      </IconButton>
      {links.map(({to, label}) => (
        <Fragment key={to}>
          <MuiLink component={Link} className="Breadcrumbs-Link" to={to}>
            {label}
          </MuiLink>
          <span className="ColorGrey">/</span>
        </Fragment>
      ))}
      <span className="Breadcrumbs-Current">{title}</span>
    </div>
  )
}
