// $duration_...
export const DURATIONS = {
  shortest: 150,
  shorter: 200,
  short: 250,
  standard: 300,
  medium: 500,
}

// $breakpoint...
export const BREAKPOINT = {
  md: 1279,
}

export default {
  typography: {
    fontFamily: 'Carnas, sans-serif',
    fontSize: 14,
    body2: {
      lineHeight: 1.5,
    },
    button: {
      fontSize: 14,
      letterSpacing: 0.5,
      fontWeight: 700,
      textTransform: 'normal',
    },
  },
  palette: {
    text: {
      primary: '#000',
      secondary: '#7d7d7d', // $color_grey
    },
    background: {
      paper: '#fff',
      default: '#f9f9f9', // $color_white
    },
    primary: {
      light: '#ffdf74', // $color_yellow-tan
      main: '#db9b46', // $color_camel
      dark: '#c78021', // $color_brownish-orange
    },
    secondary: {
      light: '#4a4a4a', // $color_black
      main: '#313131', // $color_black
      dark: '#141414', // $color_black-two
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)', // $easing_in-out
      easeOut: 'cubic-bezier(0, 0, 0.2, 1)', // $easing_out
    },
    duration: {
      shortest: DURATIONS.shortest,
      shorter: DURATIONS.shorter,
      short: DURATIONS.short,
      standard: DURATIONS.standard,
    },
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      md: BREAKPOINT.md + 1,
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
      margin: 'dense',
    },
    MuiButton: {
      disableElevation: true,
      color: 'secondary',
      variant: 'contained',
    },
    MuiTooltip: {
      enterDelay: DURATIONS.standard,
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiDialog: {
      scroll: 'body',
    },
  },
}
