import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'

import './SurveyPageForm.scss'
import {saveCourse} from '../../redux/courses'
import withSortableList from '../decorators/withSortableList'
import ExpansionBlock from '../common/ExpansionBlock'
import SelectField from '../fields/SelectField'
import TextField from '../fields/TextField'
import BlockButton from '../common/BlockButton'
import Submit from '../common/Submit'
import Answer from './Answer'
import {
  getName,
  getTicketData,
  getInitialQuestion,
  getInitialParameter,
  getInitialStep,
} from '../../utils/helpers'
import {TYPE, TYPES, PREDEFINED_GROUP} from '../../utils/constants'

SurveyPageForm.propTypes = {
  partId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  saveCourse: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
}

const SortableQuestion = SortableElement(
  ({
    uuid,
    sortOrder,
    parameters,
    values,
    notLast,
    questions,
    setQuestions,
    onDelete,
  }) => {
    const type = values[getName(uuid, 'type')]

    const handleTypeChange = (value) => {
      if (type === value) return
      const newQuestions = cloneDeep(questions)
      const currentQuestion = newQuestions.find(
        (question) => question.uuid === uuid
      )
      if (value === TYPE.text || value === TYPE.string) {
        currentQuestion.parameters = [{multiple: false, placeholder: null}]
      } else if (value === TYPE.checkbox || value === TYPE.radio) {
        currentQuestion.parameters = [getInitialParameter()]
      } else if (value === TYPE.range) {
        currentQuestion.parameters = [getInitialStep(1)]
      } else {
        currentQuestion.parameters = null
      }
      setQuestions(newQuestions)
    }

    const handeDelete = () => {
      onDelete(uuid)
    }

    return (
      <ExpansionBlock
        title={`Question ${sortOrder}`}
        onDelete={notLast ? handeDelete : undefined}
      >
        <TextField required name={getName(uuid, 'title')} label="Question" />
        <TextField name={getName(uuid, 'subtitle')} label="Explanation" />
        <SelectField
          required
          name={getName(uuid, 'type')}
          label="Answer type"
          options={TYPES}
          optionsGroup={PREDEFINED_GROUP}
          onChange={handleTypeChange}
        />
        <Answer
          id={uuid}
          type={type}
          parameters={parameters}
          questions={questions}
          setQuestions={setQuestions}
        />
      </ExpansionBlock>
    )
  }
)

const SortableQuestions = SortableContainer(
  ({questions, values, setQuestions, onDelete}) => (
    <ul className="SurveyPageForm-List">
      {questions.map((question, index) => (
        <SortableQuestion
          key={question.uuid}
          {...question}
          index={index}
          values={values}
          notLast={questions.length > 1}
          questions={questions}
          setQuestions={setQuestions}
          onDelete={onDelete}
        />
      ))}
    </ul>
  )
)

function SurveyPageForm({
  partId,
  sectionId,
  page,
  course,
  courseSaveLoading,
  items,
  saveCourse,
  setItems,
  onSortEnd,
  onItemAdd,
  onItemDelete,
}) {
  const handleSubmit = (values) => {
    const ticket = getTicketData(course)
    const currentPage = ticket.surveyData.parts
      .find((part) => part.uuid === partId)
      .sections.find((section) => section.uuid === sectionId)
      .pages.find((item) => item.uuid === page.uuid)
    currentPage.title = values.title
    const newQuestions = cloneDeep(items)
    newQuestions.forEach((question) => {
      question.title = values[getName(question.uuid, 'title')]
      question.subtitle = values[getName(question.uuid, 'subtitle')]
      question.type = values[getName(question.uuid, 'type')]
      if (question.type === TYPE.text || question.type === TYPE.string) {
        const parameter = question.parameters[0]
        parameter.placeholder = values[getName(question.uuid, 'placeholder')]
        if (question.type === TYPE.text) return
        parameter.multiple = values[getName(question.uuid, 'multiple')]
      } else if (
        question.type === TYPE.checkbox ||
        question.type === TYPE.radio
      ) {
        question.parameters.forEach((parameter) => {
          parameter.value =
            values[getName(question.uuid, parameter.uuid, 'value')]
          parameter.description =
            values[getName(question.uuid, parameter.uuid, 'description')]
          if (question.type === TYPE.radio && parameter.hideQuestions) {
            parameter.hideQuestions.forEach((hideQuestion) => {
              hideQuestion.title =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    hideQuestion.uuid,
                    'title'
                  )
                ]
              hideQuestion.subtitle =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    hideQuestion.uuid,
                    'subtitle'
                  )
                ]
              hideQuestion.parameters[0].placeholder =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    hideQuestion.uuid,
                    'placeholder'
                  )
                ]
            })
          }
          if (!parameter.additionalValue) return
          if (parameter.additionalValue.type === TYPE.range) {
            parameter.additionalValue.steps.forEach((step) => {
              step.description =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    step.uuid,
                    'description'
                  )
                ]
            })
          } else if (parameter.additionalValue.type === TYPE.selectbox) {
            parameter.additionalValue.values.forEach((value) => {
              value.value =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    value.uuid,
                    'description'
                  )
                ]
            })
          } else if (Array.isArray(parameter.additionalValue)) {
            parameter.additionalValue.forEach((item) => {
              item.placeholder =
                values[
                  getName(
                    question.uuid,
                    parameter.uuid,
                    item.uuid,
                    'placeholder'
                  )
                ]
            })
          } else {
            parameter.additionalValue.placeholder =
              values[
                getName(
                  question.uuid,
                  parameter.uuid,
                  parameter.additionalValue.uuid,
                  'placeholder'
                )
              ]
          }
        })
      } else if (question.type === TYPE.range) {
        question.parameters.forEach((parameter) => {
          parameter.description =
            values[getName(question.uuid, parameter.uuid, 'description')]
        })
      }
    })
    currentPage.questions = newQuestions
    setItems(newQuestions)
    return saveCourse(ticket)
  }

  const renderForm = ({values, pristine, handleSubmit}) => (
    <form className="SurveyPageForm" autoComplete="off" onSubmit={handleSubmit}>
      <TextField required name="title" label="Title" />
      <SortableQuestions
        useDragHandle
        questions={items}
        values={values}
        setQuestions={setItems}
        onSortEnd={onSortEnd}
        onDelete={onItemDelete}
      />
      <BlockButton label={`question ${items.length + 1}`} onClick={onItemAdd} />
      <Submit
        pristine={pristine && isEqual(items, page.questions)}
        loading={courseSaveLoading}
      />
    </form>
  )

  const initialValues = {
    title: page.title,
  }
  page.questions.forEach(({uuid, type, title, subtitle, parameters}) => {
    initialValues[getName(uuid, 'type')] = type
    initialValues[getName(uuid, 'title')] = title
    initialValues[getName(uuid, 'subtitle')] = subtitle
    if (type === TYPE.text || type === TYPE.string) {
      initialValues[getName(uuid, 'placeholder')] = parameters[0].placeholder
      if (type === TYPE.text) return
      initialValues[getName(uuid, 'multiple')] = parameters[0].multiple
    } else if (type === TYPE.checkbox || type === TYPE.radio) {
      parameters.forEach((parameter) => {
        initialValues[getName(uuid, parameter.uuid, 'value')] = parameter.value
        initialValues[getName(uuid, parameter.uuid, 'description')] =
          parameter.description
        if (type === TYPE.radio && parameter.hideQuestions) {
          parameter.hideQuestions.forEach((hideQuestion) => {
            initialValues[
              getName(uuid, parameter.uuid, hideQuestion.uuid, 'title')
            ] = hideQuestion.title
            initialValues[
              getName(uuid, parameter.uuid, hideQuestion.uuid, 'subtitle')
            ] = hideQuestion.subtitle
            initialValues[
              getName(uuid, parameter.uuid, hideQuestion.uuid, 'placeholder')
            ] = hideQuestion.parameters[0].placeholder
          })
        }
        if (!parameter.additionalValue) return
        if (parameter.additionalValue.type === TYPE.range) {
          parameter.additionalValue.steps.forEach((step) => {
            initialValues[
              getName(uuid, parameter.uuid, step.uuid, 'description')
            ] = step.description
          })
        } else if (parameter.additionalValue.type === TYPE.selectbox) {
          parameter.additionalValue.values.forEach((value) => {
            initialValues[
              getName(uuid, parameter.uuid, value.uuid, 'description')
            ] = value.value
          })
        } else if (Array.isArray(parameter.additionalValue)) {
          parameter.additionalValue.forEach((item) => {
            initialValues[
              getName(uuid, parameter.uuid, item.uuid, 'placeholder')
            ] = item.placeholder
          })
        } else {
          initialValues[
            getName(
              uuid,
              parameter.uuid,
              parameter.additionalValue.uuid,
              'placeholder'
            )
          ] = parameter.additionalValue.placeholder
        }
      })
    } else if (type === TYPE.range) {
      parameters.forEach((parameter) => {
        initialValues[getName(uuid, parameter.uuid, 'description')] =
          parameter.description
      })
    }
  })
  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSortableList(SurveyPageForm, ['page', 'questions'], getInitialQuestion))
