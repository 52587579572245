import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import cn from 'classnames'
import {Field} from 'react-final-form'
import ButtonBase from '@material-ui/core/ButtonBase'

import './ImageField.scss'
import {ReactComponent as CameraIcon} from '../../assets/icons/camera.svg'
import Loader from '../common/Loader'
import {handleError} from '../../utils/helpers'

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
  required: PropTypes.bool,
}

const ImageFieldContent = ({
  input: {name, value, onChange},
  src = null,
  meta,
  required,
}) => {
  const [image, setImage] = useState(src)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null)

  const handleChange = async (e) => {
    const file = e.target.files[0]
    if (!file) return
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    try {
      const {data} = await axios.post('/file/upload', formData)
      onChange(data.files[0])
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setImage(reader.result)
      setLoading(false)
    } catch (error) {
      handleError(error)
      setLoading(false)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    inputRef.current.click()
  }

  const disabled = loading || meta.submitting
  return (
    <label className="ImageField">
      <Loader type="block" loading={loading} />
      <ButtonBase
        focusRipple
        className={cn('ImageField-Button', {
          'ImageField-Button_disabled': disabled,
        })}
        disabled={disabled}
        onClick={handleClick}
      >
        {image ? (
          <img
            className="ImageField-Image"
            width={120}
            height={120}
            src={image}
            alt={name}
          />
        ) : (
          <>
            <CameraIcon />
            <p className="Relative">
              Logo{required && <span className="ImageField-Required">*</span>}
            </p>
          </>
        )}
      </ButtonBase>
      <input
        ref={inputRef}
        tabIndex={-1}
        className="ImageField-Input"
        name={name}
        required={required && !value}
        accept="image/*"
        type="file"
        disabled={disabled}
        onChange={handleChange}
      />
    </label>
  )
}

export default function ImageField(props) {
  return <Field {...props} component={ImageFieldContent} />
}
