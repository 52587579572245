import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Switch, Route, Redirect} from 'react-router-dom'

import {getCourse} from '../../redux/courses'
import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import Backdrop from '../common/Backdrop'
import CourseContent from './CourseContent'
import CoursePart from './CoursePart'
import Survey from './Survey'
import SurveyPage from './SurveyPage'
import Instruction from './Instruction'
import InstructionQuestion from './InstructionQuestion'
import {ROUTE, COURSE_TYPE, ACCESS_STATUSES} from '../../utils/constants'

Course.propTypes = {
  match: PropTypes.object.isRequired,
  course: PropTypes.object,
  courseSaveLoading: PropTypes.bool.isRequired,
  getCourse: PropTypes.func.isRequired,
}

const links = [{to: ROUTE.courses, label: 'Courses'}]

function Course({match, course, courseSaveLoading, getCourse}) {
  useEffect(() => {
    getCourse(match.params.id)
  }, []) // eslint-disable-line

  if (course && !ACCESS_STATUSES.includes(course.adminStatus)) {
    return <Redirect to={ROUTE.courses} />
  }

  const currentPath = `${ROUTE.courses}/:id`
  return (
    <section>
      <Backdrop loading={courseSaveLoading} />
      {!course && <Breadcrumbs links={links} title={course?.title} />}
      {course ? (
        <Switch>
          <Route exact path={currentPath} component={CourseContent} />
          <Route
            exact
            path={`${currentPath}/${COURSE_TYPE.page}/:type`}
            component={CoursePart}
          />
          <Route
            exact
            path={`${currentPath}/${COURSE_TYPE.survey}/:partId`}
            component={Survey}
          />
          <Route
            exact
            path={`${currentPath}/${COURSE_TYPE.survey}/:partId/:sectionId/:pageId`}
            component={SurveyPage}
          />
          <Route
            exact
            path={`${currentPath}/${COURSE_TYPE.instruction}`}
            component={Instruction}
          />
          <Route
            exact
            path={`${currentPath}/${COURSE_TYPE.instruction}/:questionId`}
            component={InstructionQuestion}
          />
          <Redirect to={ROUTE.courses} />
        </Switch>
      ) : (
        <Loader />
      )}
    </section>
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {getCourse}

export default connect(mapStateToProps, mapDispatchToProps)(Course)
