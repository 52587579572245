import axios from 'axios'
import {createSlice} from '@reduxjs/toolkit'

import {handleError} from '../utils/helpers'

const addAuthHeader = (session) => {
  axios.defaults.headers.common.Authorization = `${session.token_type} ${session.access_token}`
}

let session = localStorage.getItem('session')
if (session) {
  session = JSON.parse(session)
  addAuthHeader(session)
}

const {reducer, actions} = createSlice({
  name: 'auth',
  initialState: {
    session,
  },
  reducers: {
    authSuccess: (state, {payload}) => {
      state.session = payload
    },
    logout: (state) => {
      localStorage.removeItem('session')
      state.session = null
    },
  },
})

const {authSuccess} = actions
export const {logout} = actions

export const login = (values) => async (dispatch) => {
  try {
    const {data} = await axios.post(
      `${process.env.REACT_APP_API_URL}/oauth/token`,
      {
        ...values,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'password',
        scope: '*',
      }
    )
    addAuthHeader(data)
    localStorage.setItem('session', JSON.stringify(data), {
      expires: data.expires_in,
    })
    dispatch(authSuccess(data))
  } catch (error) {
    handleError(error, dispatch)
  }
}

export default reducer
