import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import './InstructionQuestionForm.scss'
import {saveCourse, allQuestionsSelector} from '../../redux/courses'
import {ReactComponent as DetailsIcon} from '../../assets/icons/details.svg'
import withDialog from '../decorators/withDialog'
import TextField from '../fields/TextField'
import Submit from '../common/Submit'
import AddButton from '../common/AddButton'
import DeleteButton from '../common/DeleteButton'
import QuestionDialog from './QuestionDialog'
import {getTicketData} from '../../utils/helpers'

InstructionQuestionForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  preDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  examples: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  allQuestions: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  saveCourse: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
const questionRegExp = /#!?QUESTION_.*?#/gi

const questionIdRegExp = /#!?QUESTION_|\$.*?#|#/gi

const mutators = {
  ...arrayMutators,
}

const Example = ({name, index, fields}) => {
  const handleClick = () => fields.remove(index)

  return (
    <div className="Relative">
      <TextField
        required={index === 0}
        multiline
        name={name}
        label={`Example ${index + 1}`}
        rows={5}
      />
      {fields.length > 1 && <DeleteButton option onClick={handleClick} />}
    </div>
  )
}

function InstructionQuestionForm({
  uuid,
  title,
  preDescription,
  description,
  examples,
  course,
  courseSaveLoading,
  allQuestions,
  open,
  saveCourse,
  onOpen,
  onClose,
}) {
  const handleSubmit = ({title, preDescription, description, examples}) => {
    const ticket = getTicketData(course)
    const ticketQuestion = ticket.videoSurveyData.questions.find(
      (question) => question.uuid === uuid
    )
    ticketQuestion.title = title
    ticketQuestion.preDescription = preDescription
    ticketQuestion.description = description
    ticketQuestion.examples = examples.filter((example) => example)
    const questionTemplates = description.match(questionRegExp)
    if (questionTemplates) {
      questionTemplates.forEach((questionTemplate) => {
        const id = questionTemplate.replace(questionIdRegExp, '')
        ticket.surveyData.parts.some((part) =>
          part.sections.some((section) => {
            const currentPage = section.pages.find((page) =>
              page.questions.some((question) => question.uuid === id)
            )
            if (currentPage) {
              currentPage.videoPitchLabel = 'short'
            }
            return currentPage
          })
        )
      })
    }
    return saveCourse(ticket)
  }

  const renderForm = ({values, pristine, form, handleSubmit}) => {
    const handleExampleAdd = () => form.mutators.push('examples')

    return (
      <>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextField required name="title" label="Title" />
          <TextField
            required
            multiline
            name="preDescription"
            label="Description"
            rows={5}
          />
          <TextField
            required
            multiline
            name="description"
            label="Instruction"
            rows={7}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className="InstructionQuestionForm-Button"
                  position="end"
                >
                  <IconButton
                    className="Action"
                    aria-label="Add question"
                    onClick={onOpen}
                  >
                    <DetailsIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FieldArray name="examples">
            {({fields}) =>
              fields.map((name, index) => (
                <Example key={name} name={name} index={index} fields={fields} />
              ))
            }
          </FieldArray>
          {values.examples.length < 5 && (
            <AddButton
              label={`example ${values.examples.length + 1}`}
              onClick={handleExampleAdd}
            />
          )}
          <Submit pristine={pristine} loading={courseSaveLoading} />
        </form>
        <QuestionDialog
          open={open}
          descriptionValue={values['description']}
          allQuestions={allQuestions}
          changeForm={form.change}
          onClose={onClose}
        />
      </>
    )
  }

  const initialValues = {
    title,
    preDescription,
    description,
    examples,
  }
  return (
    <Form
      initialValues={initialValues}
      mutators={mutators}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
  allQuestions: allQuestionsSelector(state),
})

const mapDispatchToProps = {saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(InstructionQuestionForm))
