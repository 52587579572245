import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import IconButton from '@material-ui/core/IconButton'

import './DeleteButton.scss'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'

DeleteButton.propTypes = {
  option: PropTypes.bool,
  onClick: PropTypes.func,
}

export default function DeleteButton({option, ...props}) {
  return (
    <IconButton
      {...props}
      className={cn('DeleteButton', {DeleteButton_option: option})}
      aria-label="Delete"
    >
      <CloseIcon />
    </IconButton>
  )
}
