import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'

import './ExpansionBlock.scss'
import {ReactComponent as ExpandIcon} from '../../assets/icons/expand.svg'
import DeleteButton from '../common/DeleteButton'
import DragElement from './DragElement'
import withToggle from '../decorators/withToggle'

ExpansionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
}

function ExpansionBlock({title, children, open, onDelete, onToggle}) {
  return (
    <li className="ExpansionBlock">
      <div className="Block Block_small">
        <div className="FlexCenter FlexCenter_indent">
          <DragElement />
          <p className="TextLarge">
            <strong>{title}</strong>
          </p>
        </div>
        <IconButton onClick={onToggle}>
          <ExpandIcon className={cn('Rotated', {Rotated_active: open})} />
        </IconButton>
        {onDelete && <DeleteButton onClick={onDelete} />}
      </div>
      <Collapse unmountOnExit in={open}>
        <div className="ExpansionBlock-Content">{children}</div>
      </Collapse>
    </li>
  )
}

export default withToggle(ExpansionBlock)
