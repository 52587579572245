export const ROUTE = {
  courses: '/courses',
  recipients: '/recipients',
  companies: '/companies',
}

export const COURSE_STATUS = {
  active: 'active',
  pending: 'pending',
  progress: 'progress',
}

export const ACCESS_STATUSES = [COURSE_STATUS.active, COURSE_STATUS.pending]

export const COURSE_TYPE = {
  page: 'page',
  survey: 'survey',
  instruction: 'instruction',
}

export const RECIPIENTS_SORT = ['latest', 'earliest']

export const PAGE_PART = {
  welcome: 'welcome',
  conclusion: 'conclusion',
}

export const INITIAL_TABLE_SETTINGS = {
  size: 5,
  page: 1,
}

export const TEXT_PLUG = '...'

export const MAX_STEPS = 15

export const TYPE = {
  text: 'TEXT',
  string: 'STRING',
  checkbox: 'CHECKBOX',
  radio: 'RADIO',
  range: 'RANGE',
  selectbox: 'SELECTBOX',
  selectboxIndustries: 'SELECTBOX_INDUSTRIES',
  selectboxLanguages: 'SELECTBOX_LANGUAGES',
  multiSelectboxIndustries: 'MULTI_SELECTBOX_INDUSTRIES',
  multiSelectboxHobbies: 'MULTI_SELECTBOX_HOBBIES',
  multiSelectboxDrivingDisciplines: 'MULTI_SELECTBOX_DRIVING_DISCIPLINES',
  multiSelectboxMachineOperatorDisciplines: 'MULTI_SELECTBOX_MACHINE_OPERATOR_DISCIPLINES',
  multiSelectboxCombinationVehicles: 'MULTI_SELECTBOX_COMBINATION_VEHICLES',
  multiSelectboxGearboxes: 'MULTI_SELECTBOX_GEARBOXES',
  citySelect: 'CITY_SELECT',
  employmentGrid: 'EMPLOYMENT_GRID',
  speakLanguages: 'SPEAK_LANGUAGES',
  drivingLicenseClasses: 'DRIVING_LICENSE_CLASSES',
  drivingLicenseEndorsements: 'DRIVING_LICENSE_ENDORSEMENTS',
}

export const TYPES = [
  {
    value: TYPE.text,
    label: 'Long answer',
    description: 'multiple lines field',
  },
  {
    value: TYPE.string,
    label: 'Short answer',
    description: 'single line field',
  },
  {
    value: TYPE.checkbox,
    label: 'Check list',
    description: 'checkboxes for multiple choice',
  },
  {
    value: TYPE.radio,
    label: 'Single choice',
    description: 'radio button with single choice',
  },
  {
    value: TYPE.range,
    label: 'Range',
    description: 'slider with levels',
  },
]

export const INDUSTRIES = [
  'Information technology',
  'Manufacturing',
  'Health Care and Social Assistance',
  'Financial and Insurance Services',
  'Retail',
  'Accounting and legal',
  'Construction, repair and maintenance',
  'Information Media and Telecommunications',
  'Restaurants, bars and food services',
  'Agriculture, Forestry, Fishing',
  'Electricity, Gas, Water',
  'Waste Services',
  'Transport',
  'Warehousing',
  'Rental, Hiring and Real Estate Services',
  'Administrative and Support Services',
  'Public Administration and Safety',
  'Education and Training',
  'Arts',
  'Recreation, hospitality & tourism',
  'Marketing & Advertisement',
  'Other',
]

export const LANGUAGES = [
  'Spanish',
  'English',
  'Hindi',
  'Portuguese',
  'Bengali',
  'Russian',
  'Japanese',
  'Punjabi',
  'German',
  'Javanese',
  'Wu (inc. Shanghainese)',
  'Malay (inc. Indonesian and Malaysian)',
  'Telugu',
  'Vietnamese',
  'Korean',
  'French',
  'Marathi',
  'Tamil',
  'Urdu',
  'Turkish',
  'Italian',
  'Yue (inc. Cantonese)',
  'Thai',
  'Gujarati',
  'Jin',
  'Southern Min (inc. Hokkienand Teochew)',
  'Persian',
  'Polish',
  'Pashto',
  'Kannada',
  'Xiang',
  'Malayalam',
  'Sundanese',
  'Hausa',
  'Odia (Oriya)',
  'Burmese',
  'Hakka',
  'Ukrainian',
  'Bhojpuri',
  'Tagalog (Filipino)',
  'Yoruba',
  'Maithili',
  'Uzbek',
  'Sindhi',
  'Amharic',
  'Fula',
  'Romanian',
  'Oromo',
  'Igbo',
  'Azerbaijani',
  'Awadhi',
  'Gan',
  'Cebuano (Visayan)',
  'Dutch',
  'Kurdish',
  'Serbo-Croatian',
  'Malagasy',
  'Saraiki',
  'Nepali',
  'Sinhala',
  'Chittagonian',
  'Zhuang',
  'Khmer',
  'Turkmen',
  'Assamese',
  'Madurese',
  'Somali',
  'Marwari',
  'Magahi',
  'Haryanvi',
  'Hungarian',
  'Chhattisgarhi',
  'Greek',
  'Chewa',
  'Deccan',
  'Akan',
  'Kazakh',
  'Northern Min',
  'Sylheti',
  'Zulu',
  'Czech',
  'Kinyarwanda',
  'Dhundhari',
  'Haitian Creole',
  'Eastern Min (inc. Fuzhou dialect)',
  'Ilocano',
  'Quechua',
  'Kirundi',
  'Swedish',
  'Hmong',
  'Shona',
  'Uyghur',
  'Hiligaynon / Ilonggo (Visayan)',
  'Mossi',
  'Xhosa',
  'Belarusian',
  'Balochi',
  'Konkani',
  'Maori',
]

export const PREDEFINED_GROUP = {
  title: 'Predefined',
  options: [
    {
      value: TYPE.multiSelectboxIndustries,
      label: 'Industries checklist',
    },
    {
      value: TYPE.speakLanguages,
      label: 'Languages checklist & range',
    },
    {
      value: TYPE.multiSelectboxHobbies,
      label: 'Hobbies checklist',
    },
    {
      value: TYPE.selectboxIndustries,
      label: 'Industries single choice',
    },
    {
      value: TYPE.multiSelectboxDrivingDisciplines,
      label: 'Driving disciplines checklist',
    },
    {
      value: TYPE.multiSelectboxMachineOperatorDisciplines,
      label: 'Machine operator disciplines checklist',
    },
    {
      value: TYPE.multiSelectboxCombinationVehicles,
      label: 'Combination vehicles checklist',
    },
    {
      value: TYPE.multiSelectboxGearboxes,
      label: 'Gearboxes checklist',
    },
    {
      value: TYPE.selectboxLanguages,
      label: 'Languages single choice',
    },
    {
      value: TYPE.employmentGrid,
      label: 'Employment history grid',
    },
    {
      value: TYPE.citySelect,
      label: 'City select',
    },
    {
      value: TYPE.drivingLicenseClasses,
      label: 'Driving license classes',
    },
    {
      value: TYPE.drivingLicenseEndorsements,
      label: 'Driving license endorsements',
    },
  ],
}
