import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import SurveyForm from './SurveyForm'
import {ROUTE} from '../../utils/constants'

Survey.propTypes = {
  match: PropTypes.object.isRequired,
  courseTitle: PropTypes.string.isRequired,
  survey: PropTypes.object.isRequired,
  courseLoading: PropTypes.bool.isRequired,
}

function Survey({match, courseTitle, survey, courseLoading}) {
  if (!survey) return <Redirect to={ROUTE.courses} />

  const links = [
    {to: ROUTE.courses, label: 'Courses'},
    {
      to: `${ROUTE.courses}/${match.params.id}`,
      label: courseTitle,
    },
  ]
  const title = `Survey ‘${survey.title}’`
  return (
    <div>
      <Breadcrumbs links={links} title={title} />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent Title_nowrap">{title}</h1>
        <SurveyForm currentPath={match.url} survey={survey} />
      </div>
    </div>
  )
}

const mapStateToProps = (state, {match}) => ({
  courseTitle: state.courses.course.title,
  survey: state.courses.course.parts.find(
    (part) => part.uuid === match.params.partId
  ),
  courseLoading: state.courses.courseLoading,
})

export default connect(mapStateToProps)(Survey)
