import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {v4 as generateId} from 'uuid'
import {Form} from 'react-final-form'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import {getAllCompanies, companiesOptionsSelector} from '../../redux/companies'
import {saveCourse} from '../../redux/courses'
import withDialog from '../decorators/withDialog'
import TextField from '../fields/TextField'
import SelectField from '../fields/SelectField'
import Submit from '../common/Submit'
import CloseButton from '../common/CloseButton'
import {
  getInitialVideoScriptQuestion,
  getInitialPart,
} from '../../utils/helpers'

CourseAdd.propTypes = {
  companiesOptions: PropTypes.array,
  allCompaniesLoading: PropTypes.bool.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  saveCourse: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const title = 'Create course'

const DialogContent = ({
  companiesOptions,
  allCompaniesLoading,
  courseSaveLoading,
  getAllCompanies,
  saveCourse,
  onClose,
}) => {
  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line

  const handleSubmit = (values) => {
    const newCourse = {
      ...values,
      survey: null,
      surveyData: {
        welcomeContent: 'Welcome...',
        conclusionContent: 'Conclusion...',
        parts: [getInitialPart(1)],
      },
      videoSurveyData: {
        uuid: generateId(),
        title: 'Video Script',
        description: 'Description...',
        questions: [getInitialVideoScriptQuestion(1)],
        startTitle: 'Section title...',
        startText: 'Section text...',
      },
    }
    return saveCourse(newCourse, onClose)
  }

  const renderForm = ({handleSubmit}) => (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField required name="title" label="Title" />
      <SelectField
        required
        name="company"
        label="Company"
        loading={allCompaniesLoading}
        options={companiesOptions}
      />
      <Submit loading={courseSaveLoading} />
    </form>
  )

  return (
    <>
      <CloseButton onClick={onClose} disabled={courseSaveLoading} />
      <h2 className="Title Title_indent">{title}</h2>
      <Form render={renderForm} onSubmit={handleSubmit} />
    </>
  )
}

function CourseAdd({courseSaveLoading, open, onOpen, onClose, ...props}) {
  return (
    <>
      <Button onClick={onOpen}>{title}</Button>
      <Dialog open={open} onClose={courseSaveLoading ? undefined : onClose}>
        <DialogContent
          {...props}
          courseSaveLoading={courseSaveLoading}
          onClose={onClose}
        />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  companiesOptions: companiesOptionsSelector(state),
  allCompaniesLoading: state.companies.allCompaniesLoading,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {getAllCompanies, saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(CourseAdd))
