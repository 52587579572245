import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import InstructionForm from './InstructionForm'
import {ROUTE} from '../../utils/constants'
import {Redirect} from 'react-router-dom'

Instruction.propTypes = {
  match: PropTypes.object.isRequired,
  courseTitle: PropTypes.string.isRequired,
  videoSurvey: PropTypes.object.isRequired,
  courseLoading: PropTypes.bool.isRequired,
}

function Instruction({match, courseTitle, videoSurvey, courseLoading}) {
  const backLink = `${ROUTE.courses}/${match.params.id}`

  if (!videoSurvey.questions) return <Redirect to={backLink} />

  const links = [
    {to: ROUTE.courses, label: 'Courses'},
    {to: backLink, label: courseTitle},
  ]
  const title = `Instructions ‘${videoSurvey.title}’`
  return (
    <>
      <Breadcrumbs links={links} title={title} />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent">{title}</h1>
        <InstructionForm currentPath={match.url} videoSurvey={videoSurvey} />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  courseTitle: state.courses.course.title,
  videoSurvey: state.courses.course.videoSurvey,
  courseLoading: state.courses.courseLoading,
})

export default connect(mapStateToProps)(Instruction)
