import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import AppContent from './base/AppContent'
import Auth from './base/Auth'
import Notification from './base/Notification'

App.propTypes = {
  session: PropTypes.object,
}

function App({session}) {
  return (
    <>
      {session ? <AppContent /> : <Auth />}
      <Notification />
    </>
  )
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
})

export default connect(mapStateToProps)(App)
