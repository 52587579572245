import React, {useState} from 'react'

export default (WrappedComponent) => (props) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <WrappedComponent
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}
