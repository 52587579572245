import React from 'react'
import {connect} from 'react-redux'
import copy from 'copy-to-clipboard'

import {openSuccessSnackbar} from '../../redux/ui'

const mapDispatchToProps = {
  openSuccessSnackbar,
}

export default (WrappedComponent) =>
  connect(
    null,
    mapDispatchToProps
  )(({openSuccessSnackbar, ...props}) => {
    const handleCopy = (value, label) => {
      copy(value)
      // prevent immediately close notification while its opened clicking copy
      setTimeout(
        () => openSuccessSnackbar(`${label} has been copied to clipboard.`),
        0
      )
    }

    return <WrappedComponent {...props} onCopy={handleCopy} />
  })
