import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {NavLink, Link} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import './Sidebar.scss'
import {logout} from '../../redux/auth'
import logoSvg from '../../assets/images/logo.svg'
import {ReactComponent as FormIcon} from '../../assets/icons/form.svg'
import {ReactComponent as UserIcon} from '../../assets/icons/user.svg'
import {ReactComponent as BuildingsIcon} from '../../assets/icons/buildings.svg'
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg'
import {ReactComponent as ExitIcon} from '../../assets/icons/exit.svg'
import {ROUTE} from '../../utils/constants'
import {BREAKPOINT} from '../../styles/theme'

Sidebar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
}

const links = [
  {label: 'Courses', to: ROUTE.courses, Icon: FormIcon},
  {label: 'Recipients', to: ROUTE.recipients, Icon: UserIcon},
  {label: 'Companies', to: ROUTE.companies, Icon: BuildingsIcon},
]

const renderLinks = (onClose) =>
  links.map(({label, to, Icon}) => (
    <ListItem
      key={to}
      button
      component={NavLink}
      className="Sidebar-Link"
      activeClassName="Sidebar-Link_active"
      to={to}
      onClick={onClose}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      {label}
    </ListItem>
  ))

function Sidebar({handleLogout}) {
  const [open, setOpen] = useState(false)
  const matches = useMediaQuery(`(max-width: ${BREAKPOINT.md}px)`)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <>
      {matches && (
        <IconButton
          className="SidebarButton"
          color="secondary"
          aria-label="Open drawer"
          onClick={handleOpen}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        className="Sidebar"
        open={open}
        variant={matches ? 'temporary' : 'permanent'}
        anchor="left"
        onClose={handleClose}
      >
        <div className="Sidebar-Content">
          <div className="Sidebar-Links">
            <Link
              className="Sidebar-Logo"
              to={ROUTE.courses}
              onClick={handleClose}
            >
              <img
                width={148}
                height={51}
                src={logoSvg}
                alt="Video Profile Mapping"
              />
            </Link>
            <nav>
              <List>{renderLinks(handleClose)}</List>
            </nav>
          </div>
          <IconButton
            color="default"
            className="Sidebar-Logout"
            aria-label="Logout"
            onClick={handleLogout}
          >
            <ExitIcon />
          </IconButton>
        </div>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  handleLogout: logout,
}

export default connect(null, mapDispatchToProps)(Sidebar)
