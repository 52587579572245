import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'

import {changeRecipientsCount} from '../../redux/companies'
import withDialog from '../decorators/withDialog'
import NumberField from '../fields/NumberField'
import Submit from '../common/Submit'
import CloseButton from '../common/CloseButton'

LimitChange.propTypes = {
  courseId: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  value: PropTypes.number,
  recipientsCountChangeLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  changeRecipientsCount: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

function LimitChange({
  courseId,
  minValue,
  value,
  recipientsCountChangeLoading,
  open,
  changeRecipientsCount,
  onOpen,
  onClose,
}) {
  const handleSubmit = (values) =>
    changeRecipientsCount(courseId, values, onClose)

  const renderForm = ({handleSubmit}) => (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <NumberField
        autoFocus
        required
        name="count"
        label="Amount"
        inputProps={{min: minValue}}
      />
      <Submit loading={recipientsCountChangeLoading} />
    </form>
  )

  const initialValues = {
    count: value,
  }
  return (
    <>
      <Link component="button" onClick={onOpen}>
        Change
      </Link>
      <Dialog
        open={open}
        onClose={recipientsCountChangeLoading ? undefined : onClose}
      >
        <CloseButton
          onClick={onClose}
          disabled={recipientsCountChangeLoading}
        />
        <h2 className="Title Title_indent">Change limit</h2>
        <Form
          initialValues={initialValues}
          render={renderForm}
          onSubmit={handleSubmit}
        />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  recipientsCountChangeLoading: state.companies.recipientsCountChangeLoading,
})

const mapDispatchToProps = {changeRecipientsCount}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(LimitChange))
