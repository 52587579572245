import axios from 'axios'
import {createSlice} from '@reduxjs/toolkit'

import {getTableParams, request, handleError} from '../utils/helpers'
import {INITIAL_TABLE_SETTINGS, RECIPIENTS_SORT} from '../utils/constants'
import FileDownload from 'js-file-download'

const initialFilterValue = []

const {reducer, actions} = createSlice({
  name: 'recipients',
  initialState: {
    recipients: null,
    recipientsLoading: false,
    recipientsSize: INITIAL_TABLE_SETTINGS.size,
    recipientsPage: INITIAL_TABLE_SETTINGS.page,
    recipientsSearch: null,
    recipientsSort: RECIPIENTS_SORT[0],
    recipientsCompanyFilter: initialFilterValue,
    recipientsCourseFilter: initialFilterValue,
    recipientsCityFilter: initialFilterValue,
    filters: null,
    filtersLoading: false,
  },
  reducers: {
    getRecipientsStarted: (state) => {
      state.recipientsLoading = true
    },
    getRecipientsSuccess: (state, {payload}) => {
      state.recipients = payload
      state.recipientsLoading = false
    },
    getRecipientsFailed: (state) => {
      state.recipientsLoading = false
    },
    setRecipientsSize: (state, {payload}) => {
      state.recipientsSize = payload
      state.recipientsPage = INITIAL_TABLE_SETTINGS.page
    },
    setRecipientsPage: (state, {payload}) => {
      state.recipientsPage = payload
    },
    setRecipientsSearch: (state, {payload}) => {
      state.recipientsSearch = payload
      state.recipientsPage = INITIAL_TABLE_SETTINGS.page
    },
    setRecipientsSort: (state, {payload}) => {
      state.recipientsSort = payload
    },
    setRecipientsFilter: (state, {payload: {value, type, clear}}) => {
      state.recipientsPage = INITIAL_TABLE_SETTINGS.page
      const filterType = `recipients${type}Filter`
      if (clear) {
        state.recipientsSearch = null
        state.recipientsSort = RECIPIENTS_SORT[0]
        state.recipientsCompanyFilter = initialFilterValue
        state.recipientsCourseFilter = initialFilterValue
        state.recipientsCityFilter = initialFilterValue
        state[filterType] = [value]
        return
      }
      state[filterType] = state[filterType].includes(value)
        ? state[filterType].filter((item) => item !== value)
        : [...state[filterType], value]
    },
    getFiltersStarted: (state) => {
      state.filtersLoading = true
    },
    getFiltersSuccess: (state, {payload}) => {
      state.filters = payload
      state.filtersLoading = false
    },
    getFiltersFailed: (state) => {
      state.filtersLoading = false
    },
  },
})

const {
  getRecipientsStarted,
  getRecipientsSuccess,
  getRecipientsFailed,
  getFiltersStarted,
  getFiltersSuccess,
  getFiltersFailed,
} = actions

export const {
  setRecipientsSize,
  setRecipientsPage,
  setRecipientsSearch,
  setRecipientsSort,
  setRecipientsFilter,
} = actions

export const getRecipients = () => async (dispatch, getState) => {
  request.cancel(getRecipients)
  dispatch(getRecipientsStarted())
  const state = getState().recipients
  try {
    const {data} = await axios.get(
      '/recipient/list',
      request.withCancelToken(
        getRecipients,
        getTableParams(state, 'recipients', {
          sort: `${
            state.recipientsSort === RECIPIENTS_SORT[0] ? '-' : ''
          }createdAt`,
          'filter[search]': state.recipientsSearch || undefined,
          'filter[company]': state.recipientsCompanyFilter,
          'filter[survey]': state.recipientsCourseFilter,
          'filter[city]': state.recipientsCityFilter,
        })
      )
    )
    dispatch(getRecipientsSuccess(data))
  } catch (error) {
    handleError(error, dispatch, getRecipientsFailed)
  }
}

export const getReportForAll = () => async (dispatch) => {
  console.log("Try")
  try {
    const { data } = await axios.get(`/recipient/report`)
    FileDownload(data, `present-me-report_recipients.csv`);
  } catch (error) {
    console.log(error)
    handleError(error, dispatch)
  }
}

export const getFilters = () => async (dispatch) => {
  request.cancel(getFilters)
  dispatch(getFiltersStarted())
  try {
    const {data} = await axios.get(
      '/recipient/filters',
      request.withCancelToken(getFilters)
    )
    dispatch(getFiltersSuccess(data.data))
  } catch (error) {
    handleError(error, dispatch, getFiltersFailed)
  }
}

export default reducer
