import React from 'react'
import PropTypes from 'prop-types'

import './Submit.scss'
import LoaderButton from './LoaderButton'

Submit.propTypes = {
  pristine: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
}

export default function Submit({label = 'Save', ...props}) {
  return (
    <div className="Submit">
      <LoaderButton {...props} type="submit" label={label} />
    </div>
  )
}
