import React from 'react'
import PropTypes from 'prop-types'

import './SectionPanel.scss'

SectionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default function SectionPanel({title, children}) {
  return (
    <div className="SectionPanel">
      <h1 className="Title">{title}</h1>
      <div className="SectionPanel-Action">{children}</div>
    </div>
  )
}
