import React from 'react'
import PropTypes from 'prop-types'
import MuiBackdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Backdrop.scss'

Backdrop.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default function Backdrop({loading}) {
  return (
    <MuiBackdrop className="Backdrop" open={loading}>
      <CircularProgress />
    </MuiBackdrop>
  )
}
