import React, {memo} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'

import './CompanyForm.scss'
import {saveCompany, resetApiKeys} from '../../redux/companies'
import {ReactComponent as ResetIcon} from '../../assets/icons/reset.svg'
import withClipboard from '../decorators/withClipboard'
import withDialog from '../decorators/withDialog'
import TextField from '../fields/TextField'
import ImageField from '../fields/ImageField'
import Submit from '../common/Submit'
import Backdrop from '../common/Backdrop'
import Confirmation from '../common/Confirmation'

CompanyForm.proptTypes = {
  company: PropTypes.object.isRequired,
  companySaveLoading: PropTypes.bool.isRequired,
  apiKeysResetLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onCompanySave: PropTypes.func.isRequired,
  resetApiKeys: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const CopyField = ({value, label, onCopy, ...props}) => {
  const handleClick = () => onCopy(value, label)

  return (
    <TextField
      {...props}
      label={label}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Link component="button" type="button" onClick={handleClick}>
              Copy
            </Link>
          </InputAdornment>
        ),
      }}
    />
  )
}

const ApiSection = memo(({apiKey, token, documentation, onCopy, onOpen}) => (
  <section className="CompanyForm-Section">
    <h2 className="CompanyForm-Title">Api</h2>
    <div className="CompanyForm-Reset">
      <div className="CompanyForm-Fields">
        <CopyField name="key" label="API key" value={apiKey} onCopy={onCopy} />
        <CopyField
          name="token"
          label="API token"
          value={token}
          onCopy={onCopy}
        />
      </div>
      <Link component="button" type="button" onClick={onOpen}>
        <ResetIcon className="LinkIcon" />
        Reset
      </Link>
    </div>
    <CopyField
      name="documentation"
      type="url"
      label="API documentation"
      value={documentation}
      onCopy={onCopy}
    />
  </section>
))

function CompanyForm({
  company,
  companySaveLoading,
  apiKeysResetLoading,
  open,
  onCompanySave,
  resetApiKeys,
  onCopy,
  onOpen,
  onClose,
}) {
  const renderForm = ({values, pristine, handleSubmit}) => (
    <form className="CompanyForm" autoComplete="off" onSubmit={handleSubmit}>
      <section className="CompanyForm-Section">
        <h2 className="CompanyForm-Title">Details</h2>
        <ImageField name="logo" src={company.logo} />
        <TextField required name="fullTitle" label="Title" />
        <TextField required name="title" label="Abbreviation" />
        <TextField name="contactName" label="Contact name" />
        <TextField name="contactEmail" type="email" label="Contact email" />
      </section>
      <ApiSection
        apiKey={values.key}
        token={values.token}
        documentation={values.documentation}
        onCopy={onCopy}
        onOpen={onOpen}
      />
      <Submit pristine={pristine} loading={companySaveLoading} />
    </form>
  )

  const initialValues = {
    fullTitle: company.fullTitle,
    title: company.title,
    contactName: company.contactName,
    contactEmail: company.contactEmail,
    key: company.apiKey,
    token: company.apiToken,
    documentation: company.apiDocumentation,
  }
  return (
    <>
      <Backdrop loading={companySaveLoading} />
      <Form
        initialValues={initialValues}
        render={renderForm}
        onSubmit={onCompanySave}
      />
      <Confirmation
        open={open}
        loading={apiKeysResetLoading}
        text="You're going to reset API keys"
        confirm={resetApiKeys}
        onClose={onClose}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  companySaveLoading: state.companies.companySaveLoading,
  apiKeysResetLoading: state.companies.apiKeysResetLoading,
})

const mapDispatchToProps = {
  onCompanySave: saveCompany,
  resetApiKeys,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withClipboard(withDialog(CompanyForm)))
