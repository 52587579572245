import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import './Confirmation.scss'
import CloseButton from './CloseButton'
import LoaderButton from './LoaderButton'

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function Confirmation({open, loading, text, confirm, onClose}) {
  const onConfirm = () => confirm(onClose)

  return (
    <Dialog
      className="Confirmation"
      open={open}
      onClose={loading ? undefined : onClose}
    >
      <CloseButton disabled={loading} onClick={onClose} />
      <h2 className="Title Title_indent">Confirmation</h2>
      <p className="Confirmation-Text">
        {text}. Are you sure you want to continue?
      </p>
      <div className="Confirmation-Actions">
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <LoaderButton
          className="Confirmation-Confirm"
          loading={loading}
          label="Ok"
          onClick={onConfirm}
        />
      </div>
    </Dialog>
  )
}
