import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'

import './BlockButton.scss'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'

BlockButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function BlockButton({label, ...props}) {
  return (
    <ButtonBase {...props} focusRipple className="BlockButton">
      <AddIcon className="LinkIcon" />
      Add {label}
    </ButtonBase>
  )
}
