import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  getCompanies,
  setCompaniesSize,
  setCompaniesPage,
} from '../../redux/companies'
import Table from '../common/Table'
import AvatarLabel from '../common/AvatarLabel'
import InspectLink from '../common/InspectLink'
import SectionPanel from '../common/SectionPanel'
import CompanyAdd from './CompanyAdd'
import {formatDate} from '../../utils/helpers'
import {ROUTE} from '../../utils/constants'

Companies.propTypes = {
  companies: PropTypes.object,
  companiesLoading: PropTypes.bool.isRequired,
  companiesSize: PropTypes.number.isRequired,
  companiesPage: PropTypes.number.isRequired,
  getCompanies: PropTypes.func.isRequired,
  setCompaniesSize: PropTypes.func.isRequired,
  setCompaniesPage: PropTypes.func.isRequired,
}

const columns = [
  {title: 'Name'},
  {title: 'Registered', align: 'center'},
  {title: 'Contact', align: 'center'},
  {title: 'Recipents', align: 'center'},
  {title: 'VPM Courses', align: 'center'},
  {title: '', align: 'center'},
]

const getDataRows = (data) =>
  data.map((item) => [
    <AvatarLabel src={item.logo} label={item.fullTitle} />,
    <time>{formatDate(item.createdAt)}</time>,
    <>
      <p className="TextLarge">{item.contactName}</p>
      <p className="ColorGrey">{item.contactEmail}</p>
    </>,
    <span className="TextLarge">
      {item.surveys.reduce((sum, survey) => (sum += survey.recipients), 0)}
    </span>,
    <span className="TextLarge">{item.surveys.length}</span>,
    <InspectLink to={`${ROUTE.companies}/${item.uuid}`} />,
  ])

function Companies({
  companies,
  companiesLoading,
  companiesSize,
  companiesPage,
  getCompanies,
  setCompaniesSize,
  setCompaniesPage,
}) {
  useEffect(() => {
    getCompanies()
  }, [companiesSize, companiesPage]) // eslint-disable-line

  return (
    <section>
      <SectionPanel title="Companies">
        <CompanyAdd />
      </SectionPanel>
      <Table
        columns={columns}
        data={companies}
        loading={companiesLoading}
        size={companiesSize}
        page={companiesPage}
        getDataRows={getDataRows}
        setSize={setCompaniesSize}
        setPage={setCompaniesPage}
      />
    </section>
  )
}

const mapStateToProps = (state) => ({
  companies: state.companies.companies,
  companiesLoading: state.companies.companiesLoading,
  companiesSize: state.companies.companiesSize,
  companiesPage: state.companies.companiesPage,
})

const mapDispatchToProps = {
  getCompanies,
  setCompaniesSize,
  setCompaniesPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
