import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Link from '@material-ui/core/Link'


import {
  getRecipients,
  setRecipientsSize,
  setRecipientsPage,
  setRecipientsSearch,
  setRecipientsSort,
  setRecipientsFilter,
  getFilters,
  getReportForAll,
} from '../../redux/recipients'
import {ReactComponent as FormIcon} from '../../assets/icons/form.svg'
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg'
import Table from '../common/Table'
import AvatarLabel from '../common/AvatarLabel'
import SectionPanel from '../common/SectionPanel'
import SearchForm from '../common/SearchForm'
import {formatDate} from '../../utils/helpers'
import {RECIPIENTS_SORT} from '../../utils/constants'

Recipients.propTypes = {
  recipients: PropTypes.object,
  recipientsLoading: PropTypes.bool.isRequired,
  recipientsSize: PropTypes.number.isRequired,
  recipientsPage: PropTypes.number.isRequired,
  recipientsSearch: PropTypes.string,
  recipientsSort: PropTypes.string.isRequired,
  recipientsCompanyFilter: PropTypes.array,
  recipientsCourseFilter: PropTypes.array,
  recipientsCityFilter: PropTypes.array,
  filters: PropTypes.object,
  filtersLoading: PropTypes.bool.isRequired,
  getRecipients: PropTypes.func.isRequired,
  setRecipientsSize: PropTypes.func.isRequired,
  setRecipientsPage: PropTypes.func.isRequired,
  setRecipientsSearch: PropTypes.func.isRequired,
  setRecipientsSort: PropTypes.func.isRequired,
  setRecipientsFilter: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
}

const getFilterOptions = (filters, selected, prop) =>
  filters[prop].map((filter) => ({
    value: Array.isArray(filter) ? filter : filter.uuid,
    label: Array.isArray(filter) ? filter : filter.title,
    selected: selected.some((item) => item === (filter.uuid || filter)),
  }))

const getDataRows = (data) =>
  data.map((item) => [
    <AvatarLabel src={item.photo} label={item.name}>
      <p className="ColorGrey">
        {item.email}
        <br />
        {item.phone}
      </p>
    </AvatarLabel>,
    <span className="TextLarge">{item.company}</span>,
    item.survey,
    item.appPassword,
    item.city,
    <time>{formatDate(item.createdAt)}</time>,
    <Link
      href={`${process.env.REACT_APP_SURVEY_URL}/user/${item.code}`}
      target="_blank"
    >
      <FormIcon className="LinkIcon" width={14} height={12} />
      Course
    </Link>,
    <Link
      href={`${process.env.REACT_APP_PROFILE_URL}/${item.uuid}`}
      target="_blank"
    >
      <ProfileIcon className="LinkIcon" />
      CV
    </Link>,
  ])

function Recipients({
  recipients,
  recipientsLoading,
  recipientsSize,
  recipientsPage,
  recipientsSearch,
  recipientsSort,
  recipientsCompanyFilter,
  recipientsCourseFilter,
  recipientsCityFilter,
  filters,
  filtersLoading,
  getRecipients,
  setRecipientsSize,
  setRecipientsPage,
  setRecipientsSearch,
  setRecipientsSort,
  setRecipientsFilter,
  getFilters,
  getReportForAll,
}) {

  const handleReport = () => {
    getReportForAll()
  }

  useEffect(() => {
    getFilters()
  }, []) // eslint-disable-line

  useEffect(() => {
    getRecipients()
  }, [
    recipientsSize,
    recipientsPage,
    recipientsSearch,
    recipientsSort,
    recipientsCompanyFilter,
    recipientsCourseFilter,
    recipientsCityFilter,
    getRecipients,
  ])

  const columns = [
    {title: 'Name'},
    {
      title: 'Company',
      align: 'center',
      filter: filters && {
        type: 'Company',
        items: getFilterOptions(filters, recipientsCompanyFilter, 'companies'),
      },
    },
    {
      title: 'VPM Course',
      align: 'center',
      filter: filters && {
        type: 'Course',
        items: getFilterOptions(filters, recipientsCourseFilter, 'surveys'),
      },
    },
    {
      title: 'Password',
      align: 'center',
    },
    {
      title: 'City / Town',
      align: 'center',
      filter: filters && {
        type: 'City',
        items: getFilterOptions(filters, recipientsCityFilter, 'cities'),
      },
    },
    {
      align: 'center',
      sortItems: RECIPIENTS_SORT,
    },
    {title: '', align: 'center'},
    {title: '', align: 'center'},
  ]

  return (
    <section>
      <SectionPanel title="Recipients">
        <SearchForm
          search={recipientsSearch}
          placeholder="Search by name, email and phone number"
          setSearch={setRecipientsSearch}
        />
        <Link component="button" type="button" onClick={handleReport}>Export all recipients</Link>
      </SectionPanel>

      <Table
        columns={columns}
        data={recipients}
        loading={recipientsLoading || filtersLoading}
        size={recipientsSize}
        page={recipientsPage}
        sort={recipientsSort}
        getDataRows={getDataRows}
        setSize={setRecipientsSize}
        setPage={setRecipientsPage}
        setSort={setRecipientsSort}
        setFilter={setRecipientsFilter}
      />
    </section>
  )
}

const mapStateToProps = (state) => ({
  recipients: state.recipients.recipients,
  recipientsLoading: state.recipients.recipientsLoading,
  recipientsSize: state.recipients.recipientsSize,
  recipientsPage: state.recipients.recipientsPage,
  recipientsSearch: state.recipients.recipientsSearch,
  recipientsSort: state.recipients.recipientsSort,
  recipientsCompanyFilter: state.recipients.recipientsCompanyFilter,
  recipientsCourseFilter: state.recipients.recipientsCourseFilter,
  recipientsCityFilter: state.recipients.recipientsCityFilter,
  filters: state.recipients.filters,
  filtersLoading: state.recipients.filtersLoading,
})

const mapDispatchToProps = {
  getRecipients,
  setRecipientsSize,
  setRecipientsPage,
  setRecipientsSearch,
  setRecipientsSort,
  setRecipientsFilter,
  getFilters,
  getReportForAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipients)
