import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import cn from 'classnames'

import {
  getCourses,
  setCoursesSize,
  setCoursesPage,
  resetCourse,
} from '../../redux/courses'
import SectionPanel from '../common/SectionPanel'
import Table from '../common/Table'
import InspectLink from '../common/InspectLink'
import CourseAdd from './CourseAdd'
import CourseDuplicate from './CourseDuplicate'
import {ROUTE, COURSE_STATUS, ACCESS_STATUSES} from '../../utils/constants'

Courses.propTypes = {
  courses: PropTypes.object,
  coursesLoading: PropTypes.bool.isRequired,
  coursesSize: PropTypes.number.isRequired,
  coursesPage: PropTypes.number.isRequired,
  course: PropTypes.object,
  getCourses: PropTypes.func.isRequired,
  setCoursesSize: PropTypes.func.isRequired,
  setCoursesPage: PropTypes.func.isRequired,
  resetCourse: PropTypes.func.isRequired,
}

const columns = [
  {title: 'Course Title'},
  {title: 'Status', align: 'center'},
  {title: 'Company', align: 'center'},
  {title: '', align: 'center'},
  {title: '', align: 'center'},
]

function Courses({
  courses,
  coursesLoading,
  coursesSize,
  coursesPage,
  course,
  getCourses,
  setCoursesSize,
  setCoursesPage,
  resetCourse,
}) {
  useEffect(() => {
    getCourses()
  }, [coursesSize, coursesPage]) // eslint-disable-line

  const getDataRows = (data) =>
    data.map((item) => {
      const handleClick = () => {
        if (course && item.uuid !== course.uuid) resetCourse()
      }

      return [
        <span className="TextLarge">{item.title}</span>,
        <p
          className={cn('Label', {
            Label_type_active: item.adminStatus === COURSE_STATUS.active,
            Label_type_pending: item.adminStatus === COURSE_STATUS.pending,
          })}
        >
          {item.adminStatus === COURSE_STATUS.active
            ? 'Approved'
            : item.adminStatus === COURSE_STATUS.pending
            ? 'Pending'
            : 'In progress'}
        </p>,
        <span className="TextLarge">{item.company.fullTitle}</span>,
        <CourseDuplicate
          id={item.uuid}
          title={item.title}
          companyId={item.company.uuid}
        />,
        <InspectLink
          to={`${ROUTE.courses}/${item.uuid}`}
          disabled={!ACCESS_STATUSES.includes(item.adminStatus)}
          onClick={handleClick}
        />,
      ]
    })

  return (
    <>
      <section>
        <SectionPanel title="Courses">
          <CourseAdd />
        </SectionPanel>
        <Table
          columns={columns}
          data={courses}
          loading={coursesLoading}
          size={coursesSize}
          page={coursesPage}
          getDataRows={getDataRows}
          setSize={setCoursesSize}
          setPage={setCoursesPage}
        />
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  coursesLoading: state.courses.coursesLoading,
  coursesSize: state.courses.coursesSize,
  coursesPage: state.courses.coursesPage,
  course: state.courses.course,
})

const mapDispatchToProps = {
  getCourses,
  setCoursesSize,
  setCoursesPage,
  resetCourse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
