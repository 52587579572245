import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Loader.scss'

Loader.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string,
}

export default function Loader({loading, type}) {
  const content = (
    <div className={cn('Loader', {[`Loader_type_${type}`]: type})}>
      <CircularProgress />
    </div>
  )
  if (loading === undefined) return content
  return (
    <Fade unmountOnExit in={loading}>
      {content}
    </Fade>
  )
}
