import axios from 'axios'
import {createSlice, createSelector} from '@reduxjs/toolkit'
import {v4 as generateId} from 'uuid'

import {openSuccessSnackbar} from './ui'
import {
  getTicketData,
  getTableParams,
  request,
  handleError,
} from '../utils/helpers'
import {TYPE, INITIAL_TABLE_SETTINGS, COURSE_STATUS} from '../utils/constants'

const {reducer, actions} = createSlice({
  name: 'courses',
  initialState: {
    courses: null,
    coursesLoading: false,
    coursesSize: INITIAL_TABLE_SETTINGS.size,
    coursesPage: INITIAL_TABLE_SETTINGS.page,
    allCourses: null,
    allCoursesLoading: false,
    course: null,
    courseLoading: false,
    courseSaveLoading: false,
  },
  reducers: {
    getCoursesStarted: (state) => {
      state.coursesLoading = true
    },
    getCoursesSuccess: (state, {payload}) => {
      state.courses = payload
      state.coursesLoading = false
    },
    getCoursesFailed: (state) => {
      state.coursesLoading = false
    },
    setCoursesSize: (state, {payload}) => {
      state.coursesSize = payload
      state.coursesPage = INITIAL_TABLE_SETTINGS.page
    },
    setCoursesPage: (state, {payload}) => {
      state.coursesPage = payload
    },
    getCourseStarted: (state) => {
      state.courseLoading = true
    },
    getCourseSuccess: (state, {payload}) => {
      state.course = payload
      state.courseLoading = false
    },
    getCourseFailed: (state) => {
      state.courseLoading = false
    },
    resetCourse: (state) => {
      state.course = null
    },
    saveCourseStarted: (state) => {
      state.courseSaveLoading = true
    },
    saveCourseSuccess: (state, {payload}) => {
      if (payload) {
        state.course = payload
      }
      state.courseSaveLoading = false
    },
    saveCourseFailed: (state) => {
      state.courseSaveLoading = false
    },
  },
})

const {
  getCoursesStarted,
  getCoursesSuccess,
  getCoursesFailed,
  getCourseStarted,
  getCourseSuccess,
  getCourseFailed,
  saveCourseStarted,
  saveCourseSuccess,
  saveCourseFailed,
} = actions

export const {setCoursesSize, setCoursesPage, resetCourse} = actions

export const getCourses = () => async (dispatch, getState) => {
  request.cancel(getCourses)
  dispatch(getCoursesStarted())
  const state = getState().courses
  try {
    const {data} = await axios.get(
      '/survey/list',
      request.withCancelToken(getCourses, getTableParams(state, 'courses'))
    )
    dispatch(getCoursesSuccess(data))
  } catch (error) {
    handleError(error, dispatch, getCoursesFailed)
  }
}

const getCourseTicket = (course, ticket) => ({
  ...course,
  welcomeContent: ticket.surveyData.welcomeContent,
  conclusionContent: ticket.surveyData.conclusionContent,
  parts: ticket.surveyData.parts,
  videoSurvey: ticket.videoSurveyData,
})

const requestCourseGet = async (id) => {
  const courseResponse = await axios.get(
    `/survey/${id}`,
    request.withCancelToken(getCourse)
  )
  let course = courseResponse.data.data
  if (course.adminStatus !== COURSE_STATUS.active) {
    const ticketResponse = await axios.get(
      `/surveyTicket/${course.lastTicketUuid}`
    )
    const ticket = ticketResponse.data.data
    course = getCourseTicket(course, ticket)
  } else {
    course.parts.forEach((part) => {
      part.sections.forEach((section) => {
        section.pages.forEach((page) => {
          page.questions.forEach((question) => {
            question.parameters &&
              question.parameters.forEach((parameter) => {
                if (
                  question.type === TYPE.checkbox ||
                  question.type === TYPE.radio ||
                  question.type === TYPE.range
                ) {
                  parameter.uuid = generateId()
                  if (question.type === TYPE.radio && parameter.hideQuestions) {
                    const mappedQuestions = parameter.hideQuestions.map(
                      (hideQuestion) =>
                        page.questions.find(
                          (question) => question.uuid === hideQuestion
                        )
                    )
                    page.questions = page.questions.filter(
                      (question) =>
                        !parameter.hideQuestions.includes(question.uuid)
                    )
                    parameter.hideQuestions = mappedQuestions
                  }
                  if (!parameter.additionalValue) return
                  if (parameter.additionalValue.type === TYPE.range) {
                    parameter.additionalValue.steps.forEach((step) => {
                      step.uuid = generateId()
                      step.value = +step.value
                    })
                  } else if (Array.isArray(parameter.additionalValue)) {
                    parameter.additionalValue.forEach((item) => {
                      item.uuid = generateId()
                    })
                  } else {
                    parameter.additionalValue.uuid = generateId()
                  }
                }
              })
          })
        })
      })
    })
  }
  return course
}

export const getCourse = (id) => async (dispatch) => {
  request.cancel(getCourse)
  dispatch(getCourseStarted())
  try {
    const course = await requestCourseGet(id)
    dispatch(getCourseSuccess(course))
  } catch (error) {
    handleError(error, dispatch, getCourseFailed)
  }
}

export const saveCourse = (ticket, closeDialog, duplicateId) => async (
  dispatch,
  getState
) => {
  dispatch(saveCourseStarted())
  const state = getState().courses
  let ticketData = ticket
  try {
    if (duplicateId) {
      const course = await requestCourseGet(duplicateId)
      ticketData = {...getTicketData(course), ...ticket}
    }
    const {data} = await axios.post(`/surveyTicket/new`, ticketData)
    if (ticket.survey) {
      dispatch(saveCourseSuccess(getCourseTicket(state.course, data.data)))
      dispatch(
        openSuccessSnackbar('Course edit ticket has been updated successfully.')
      )
      return
    }
    dispatch(getCourses())
    dispatch(saveCourseSuccess())
    dispatch(
      openSuccessSnackbar(
        `Course has been ${
          duplicateId ? 'duplicated' : 'created'
        } successfully.`
      )
    )
    closeDialog && closeDialog()
  } catch (error) {
    handleError(error, dispatch, saveCourseFailed)
  }
}

const partsSelector = (state) => state.courses.course.parts
export const allQuestionsSelector = createSelector(partsSelector, (parts) => {
  const allQuestions = parts.map((part) => ({
    uuid: part.uuid,
    sortOrder: part.sortOrder,
    sections: part.sections.map((section) => ({
      uuid: section.uuid,
      sortOrder: section.sortOrder,
      title: section.title,
      questions: section.pages.reduce((sectionQuestions, page) => {
        page.questions.forEach((question) => {
          const newQuestion = question
          if (
            !sectionQuestions.some(
              (sectionQuestion) => sectionQuestion.uuid === question.uuid
            )
          )
            sectionQuestions.push(newQuestion)
          if (
            question.parameters?.some((parameter) => parameter.hideQuestions)
          ) {
            question.parameters.forEach((parameter) => {
              parameter.hideQuestions &&
                parameter.hideQuestions.forEach((hiddenQuestion) => {
                  sectionQuestions.push({
                    ...hiddenQuestion,
                    hiddenBy: {
                      uuid: question.uuid,
                      value: parameter.value,
                    },
                  })
                })
            })
          }
        })
        return sectionQuestions
      }, []),
    })),
  }))
  return allQuestions
})

export default reducer
