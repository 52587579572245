import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'

import './AvatarLabel.scss'

AvatarLabel.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default function AvatarLabel({src, label, children}) {
  return (
    <div className="AvatarLabel">
      <Avatar src={src} alt={label} />
      <div>
        <p className="TextLarge">{label}</p>
        {children}
      </div>
    </div>
  )
}
