import React, {useState} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import Pagination from '@material-ui/lab/Pagination'

import './Table.scss'
import {ReactComponent as DropdownIcon} from '../../assets/icons/dropdown.svg'
import CheckboxIcon from '../common/CheckboxIcon'
import Loader from '../common/Loader'
import {INITIAL_TABLE_SETTINGS} from '../../utils/constants'

Table.proptTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  getDataRows: PropTypes.func.isRequired,
  setSize: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilter: PropTypes.func,
}

const tableSizes = [INITIAL_TABLE_SETTINGS.size, 10, 20]

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

const isFiltered = (filter) =>
  filter && filter.items.some((item) => item.selected)

const RadioIcon = ({checked}) => (
  <div
    className={cn('Table-RadioIcon', {
      'Table-RadioIcon_checked': checked,
    })}
  />
)

const TableSelect = ({size, setSize}) => {
  const onChange = (e) => setSize(e.target.value)

  return (
    <Select value={size} onChange={onChange}>
      {tableSizes.map((tatbleSize) => (
        <MenuItem key={tatbleSize} value={tatbleSize}>
          {tatbleSize} rows
        </MenuItem>
      ))}
    </Select>
  )
}

const TablePagination = ({page, totalPages, setPage}) => {
  const onChange = (e, page) => setPage(page)

  return (
    <Pagination
      showFirstButton
      showLastButton
      page={page}
      count={totalPages}
      size="large"
      onChange={onChange}
    />
  )
}

const HeadColumnFilter = ({
  align,
  title,
  filter,
  sortItems,
  sort,
  setFilter,
  setSort,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (e) => setAnchorEl(e.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleFilterSet = (value) => () => {
    if (filter) {
      setFilter({value, type: filter.type})
    } else {
      setSort(value)
      handleClose()
    }
  }

  const label = title || sort
  return (
    <>
      <TableCell
        align={align}
        className={cn('Table-Cell', {
          'Table-Cell_filtered': isFiltered(filter),
        })}
      >
        <Link component="button" className="Table-Filter" onClick={handleOpen}>
          {label}
          <DropdownIcon
            className={cn('Table-FilterIcon Rotated', {
              Rotated_active: anchorEl,
            })}
          />
        </Link>
      </TableCell>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        onClose={handleClose}
      >
        {filter
          ? filter.items.map(({value, label, selected}) => (
              <MenuItem
                key={value}
                className="Table-FilterItem"
                selected={selected}
                onClick={handleFilterSet(value)}
              >
                <Checkbox
                  disableRipple
                  edge="start"
                  tabIndex={-1}
                  checked={selected}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxIcon checked />}
                />
                {label}
              </MenuItem>
            ))
          : sortItems.map((item) => (
              <MenuItem
                key={item}
                className="Table-FilterItem"
                selected={item === sort}
                onClick={handleFilterSet(item)}
              >
                <Radio
                  disableRipple
                  edge="start"
                  value={item}
                  checked={item === sort}
                  icon={<RadioIcon />}
                  checkedIcon={<RadioIcon checked />}
                />
                {item}
              </MenuItem>
            ))}
      </Menu>
    </>
  )
}

export default function Table({
  columns,
  data,
  loading,
  size,
  page,
  sort,
  getDataRows,
  setSize,
  setPage,
  setSort,
  setFilter,
}) {
  if (!data) return <Loader />

  const rows = getDataRows(data.data)
  return (
    <TableContainer className="Table" component={Paper}>
      <Loader loading={loading} type="table" />
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((column, index) =>
              (column.filter && column.filter.items.length) ||
              column.sortItems ? (
                <HeadColumnFilter
                  key={index}
                  {...column}
                  sort={sort}
                  setFilter={setFilter}
                  setSort={setSort}
                />
              ) : (
                <TableCell key={index} align={column.align}>
                  {column.title}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length ? (
            rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={cn('Table-Cell', {
                      'Table-Cell_filtered': isFiltered(
                        columns[cellIndex].filter
                      ),
                    })}
                    align={columns[cellIndex].align}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <span className="ColorGrey">No data</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      <div className="Table-Footer">
        <TableSelect size={size} setSize={setSize} />
        <TablePagination
          page={page}
          totalPages={data.meta.last_page}
          setPage={setPage}
        />
      </div>
    </TableContainer>
  )
}
