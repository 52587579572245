import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import SurveyPageForm from './SurveyPageForm'
import {ROUTE, COURSE_TYPE} from '../../utils/constants'

SurveyPage.propTypes = {
  match: PropTypes.object.isRequired,
  courseTitle: PropTypes.string.isRequired,
  surveyTitle: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
  courseLoading: PropTypes.bool.isRequired,
}

function SurveyPage({match, courseTitle, surveyTitle, page, courseLoading}) {
  if (!surveyTitle || !page) return <Redirect to={ROUTE.courses} />

  const coursePath = `${ROUTE.courses}/${match.params.id}`
  const links = [
    {to: ROUTE.courses, label: 'Courses'},
    {
      to: coursePath,
      label: courseTitle,
    },
    {
      to: `${coursePath}/${COURSE_TYPE.survey}/${match.params.partId}`,
      label: `Survey ‘${surveyTitle}‘`,
    },
  ]
  const title = `Page ‘${page.title}’`
  return (
    <div>
      <Breadcrumbs links={links} title={title} />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent Title_nowrap">{title}</h1>
        <SurveyPageForm
          partId={match.params.partId}
          sectionId={match.params.sectionId}
          page={page}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, {match}) => ({
  courseTitle: state.courses.course.title,
  surveyTitle: state.courses.course.parts.find(
    (part) => part.uuid === match.params.partId
  )?.title,
  page: state.courses.course.parts
    .find((part) => part.uuid === match.params.partId)
    ?.sections.find((section) => section.uuid === match.params.sectionId)
    ?.pages.find((page) => page.uuid === match.params.pageId),
  courseLoading: state.courses.courseLoading,
})

export default connect(mapStateToProps)(SurveyPage)
