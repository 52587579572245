import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import cloneDeep from 'lodash.clonedeep'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Link from '@material-ui/core/Link'

import './QuestionDialog.scss'
import SearchForm from '../common/SearchForm'
import CloseButton from '../common/CloseButton'

QuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  descriptionValue: PropTypes.string,
  allQuestions: PropTypes.array.isRequired,
  changeForm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const Question = ({
  id,
  title,
  hiddenBy,
  descriptionValue,
  changeForm,
  onClose,
}) => {
  const templateId = `#${hiddenBy ? '!' : ''}QUESTION_${id}#`

  const handleDescriptionChange = () => {
    changeForm('description', `${descriptionValue} ${templateId}`)
    onClose()
  }

  const selected = descriptionValue.includes(templateId)
  return (
    <ListItem
      button
      component="li"
      className={cn('QuestionDialog-Item', {
        'QuestionDialog-Item_selected': selected,
        'QuestionDialog-Item_hiddenBy': hiddenBy,
      })}
      disabled={selected}
      onClick={handleDescriptionChange}
    >
      {hiddenBy && (
        <span className="QuestionDialog-Value">[{hiddenBy.value}]</span>
      )}
      {title}
      {selected && <p className="QuestionDialog-Selected">{id}</p>}
    </ListItem>
  )
}

export default function QuestionDialog({
  open,
  descriptionValue = '',
  allQuestions,
  changeForm,
  onClose,
}) {
  const [search, setSearch] = useState(null)
  const [questions, setQuestions] = useState(allQuestions)

  useEffect(() => {
    if (search) {
      let filteredQuestions = cloneDeep(allQuestions)
      filteredQuestions = filteredQuestions.filter((part) => {
        part.sections = part.sections.filter((section) => {
          section.questions = section.questions.filter((question) =>
            question.title.toLowerCase().includes(search.toLowerCase())
          )
          return section.questions.length
        })
        return part.sections.length
      })
      setQuestions(filteredQuestions)
    } else {
      setQuestions(allQuestions)
    }
  }, [search, allQuestions])

  const handleSearchClear = () => setSearch(null)

  return (
    <Dialog
      fullWidth
      className="QuestionDialog"
      open={open}
      maxWidth="md"
      onClose={onClose}
    >
      <CloseButton onClick={onClose} />
      <div className="QuestionDialog-Panel">
        <h2 className="QuestionDialog-Title Title">Select question</h2>
        <div className="QuestionDialog-Search">
          <SearchForm
            search={search}
            placeholder="Search by any word…"
            setSearch={setSearch}
          />
        </div>
      </div>
      {questions.length ? (
        questions.map((part) => (
          <div key={part.uuid} className="QuestionDialog-Part">
            <h3 className="QuestionDialog-PartTitle">Part {part.sortOrder}</h3>
            {part.sections.map((section) => (
              <div key={section.uuid} className="QuestionDialog-Section">
                <h4 className="QuestionDialog-SectionTitle">
                  Section {section.sortOrder} ‘{section.title}’
                </h4>
                <List>
                  {section.questions.map(({uuid, title, hiddenBy}) => (
                    <Question
                      key={uuid}
                      id={uuid}
                      title={title}
                      hiddenBy={hiddenBy}
                      descriptionValue={descriptionValue}
                      changeForm={changeForm}
                      onClose={onClose}
                    />
                  ))}
                </List>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p className="QuestionDialog-Plug">
          No questions...{' '}
          <Link component="button" onClick={handleSearchClear}>
            Clear search
          </Link>
        </p>
      )}
    </Dialog>
  )
}
