import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import isEqual from 'lodash.isequal'

import './CourseContent.scss'
import {saveCourse} from '../../redux/courses'
import withSortableList from '../decorators/withSortableList'
import Breadcrumbs from '../common/Breadcrumbs'
import InspectLink from '../common/InspectLink'
import DragElement from '../common/DragElement'
import AddButton from '../common/AddButton'
import Loader from '../common/Loader'
import Submit from '../common/Submit'
import DeleteButton from '../common/DeleteButton'
import {getTicketData, checkOriginal, getInitialPart} from '../../utils/helpers'
import {ROUTE, COURSE_TYPE, PAGE_PART} from '../../utils/constants'

CourseContent.propTypes = {
  match: PropTypes.object.isRequired,
  course: PropTypes.object,
  courseLoading: PropTypes.bool.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  saveCourse: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
}

const getPagesLength = (sections) =>
  sections.reduce((sum, section) => sum + section.pages.length, 0)

const CourseBlock = ({
  id,
  original,
  drag,
  title,
  type,
  sections,
  pages,
  to,
  notLast,
  onDelete,
}) => {
  const handeDelete = id
    ? () => {
        onDelete(id)
      }
    : undefined

  return (
    <div className="Block">
      {drag ? <DragElement /> : <div className="CourseContent-Plug" />}
      <p className="CourseContent-BlockItem TextLarge CutText">{title}</p>
      <p className="CourseContent-BlockItem">
        Type: <span className="TextSemiBold">{type}</span>
      </p>
      <p className="CourseContent-BlockItem ColorGrey">
        {sections && `${sections} sections`}
      </p>
      <p className="CourseContent-BlockItem ColorGrey">
        {pages && `${pages} pages`}
      </p>
      {to ? (
        <InspectLink to={to} disabled={!original} />
      ) : (
        <div className="CourseContent-Plug" />
      )}
      {id && notLast && <DeleteButton onClick={handeDelete} />}
    </div>
  )
}

const SortableItem = SortableElement((props) => <CourseBlock {...props} />)

const SortableList = SortableContainer(
  ({surveys, originalSurveys, currentPath, onClick, onDelete}) => (
    <div className="BlocksGroup">
      {surveys.map((survey, index) => (
        <SortableItem
          key={survey.uuid}
          drag
          id={survey.uuid}
          index={index}
          original={checkOriginal(originalSurveys, survey.uuid)}
          title={survey.title}
          type="Survey"
          sections={survey.sections.length}
          pages={getPagesLength(survey.sections)}
          to={`${currentPath}/${COURSE_TYPE.survey}/${survey.uuid}`}
          notLast={surveys.length > 1}
          onDelete={onDelete}
        />
      ))}
      <div className="Block Block_simple">
        <AddButton label="part" onClick={onClick} />
      </div>
    </div>
  )
)

function CourseContent({
  match,
  course,
  courseLoading,
  courseSaveLoading,
  items,
  saveCourse,
  onSortEnd,
  onItemAdd,
  onItemDelete,
}) {
  const handleSave = () => {
    const ticket = getTicketData(course)
    ticket.surveyData.parts = items
    return saveCourse(ticket)
  }

  const currentPath = match.url
  const links = [{to: ROUTE.courses, label: 'Courses'}]
  return (
    <div className="CourseContent">
      <Breadcrumbs links={links} title={course.title} />
      <div className="Relative">
        <Loader loading={courseLoading} type="block" />
        <h1 className="Title Title_indent">{course.title}</h1>
        <CourseBlock
          original
          title="Welcome"
          type="Page"
          to={`${currentPath}/${COURSE_TYPE.page}/${PAGE_PART.welcome}`}
        />
        <SortableList
          useDragHandle
          surveys={items}
          originalSurveys={course.parts}
          currentPath={currentPath}
          onSortEnd={onSortEnd}
          onClick={onItemAdd}
          onDelete={onItemDelete}
        />
        <CourseBlock
          original
          title={course.videoSurvey.title}
          type="Instruction"
          pages={course.videoSurvey.questions?.length}
          to={`${currentPath}/${COURSE_TYPE.instruction}`}
        />
        <CourseBlock original title="Video Recording" type="Instruction" />
        <CourseBlock
          original
          title="Conclusion"
          type="Page"
          to={`${currentPath}/${COURSE_TYPE.page}/${PAGE_PART.conclusion}`}
        />
      </div>
      <Submit
        pristine={isEqual(items, course.parts)}
        loading={courseSaveLoading}
        onClick={handleSave}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseLoading: state.courses.courseLoading,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSortableList(CourseContent, ['course', 'parts'], getInitialPart))
