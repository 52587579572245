import React from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-final-form'

import TextField from '../fields/TextField'

SearchForm.propTypes = {
  search: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  setSearch: PropTypes.func,
}

export default function SearchForm({search, placeholder, setSearch}) {
  const handleSubmit = (values) => {
    setSearch(values.search)
  }

  const handleChange = (value) => !value && setSearch(null)

  const renderForm = ({handleSubmit}) => (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        name="search"
        type="search"
        margin="none"
        placeholder={placeholder}
        onChange={handleChange}
      />
    </form>
  )

  return (
    <Form
      initialValues={{search}}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}
