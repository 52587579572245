import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import isEqual from 'lodash.isequal'

import './InstructionForm.scss'
import {saveCourse} from '../../redux/courses'
import withSortableList from '../decorators/withSortableList'
import TextField from '../fields/TextField'
import InspectLink from '../common/InspectLink'
import DragElement from '../common/DragElement'
import BlockButton from '../common/BlockButton'
import DeleteButton from '../common/DeleteButton'
import Submit from '../common/Submit'
import {
  getTicketData,
  checkOriginal,
  getInitialVideoScriptQuestion,
} from '../../utils/helpers'

InstructionForm.propTypes = {
  currentPath: PropTypes.string.isRequired,
  videoSurvey: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  saveCourse: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
}

const SortableItem = SortableElement(
  ({currentPath, original, question, notLast, onDelete}) => {
    const handeDelete = () => {
      onDelete(question.uuid)
    }

    return (
      <li className="Block Block_small">
        <div className="FlexCenter FlexCenter_indent">
          <DragElement />
          <p className="TextLarge">
            <strong className="InstructionForm-Number">
              Step {question.sortOrder}
            </strong>
            {question.title}
          </p>
        </div>
        {
          <InspectLink
            to={`${currentPath}/${question.uuid}`}
            disabled={!original}
          />
        }
        {notLast && <DeleteButton onClick={handeDelete} />}
      </li>
    )
  }
)

const SortableList = SortableContainer(
  ({questions, originalQuestions, currentPath, onDelete}) => (
    <ul className="InstructionForm-List">
      {questions.map((question, index) => (
        <SortableItem
          key={question.uuid}
          index={index}
          original={checkOriginal(originalQuestions, question.uuid)}
          currentPath={currentPath}
          question={question}
          notLast={questions.length > 1}
          onDelete={onDelete}
        />
      ))}
    </ul>
  )
)

function InstructionForm({
  currentPath,
  videoSurvey,
  course,
  courseSaveLoading,
  items,
  saveCourse,
  onSortEnd,
  onItemAdd,
  onItemDelete,
}) {
  const handleSubmit = (values) => {
    const ticket = getTicketData(course)
    const {videoSurveyData} = ticket
    videoSurveyData.title = values.title
    videoSurveyData.description = values.description
    videoSurveyData.startTitle = values.startTitle
    videoSurveyData.startText = values.startText
    videoSurveyData.questions = items
    return saveCourse(ticket)
  }

  const renderForm = ({pristine, handleSubmit}) => (
    <form
      className="InstructionForm"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField required name="title" label="Title" />
      <TextField
        required
        multiline
        name="description"
        label="Description"
        rows={8}
      />
      <TextField required name="startTitle" label="Section title" />
      <TextField
        required
        multiline
        name="startText"
        label="Section description"
        rows={8}
      />
      <SortableList
        useDragHandle
        questions={items}
        originalQuestions={videoSurvey.questions}
        currentPath={currentPath}
        onSortEnd={onSortEnd}
        onDelete={onItemDelete}
      />
      <BlockButton label={`Step ${items.length + 1}`} onClick={onItemAdd} />
      <Submit
        pristine={pristine && isEqual(items, videoSurvey.questions)}
        loading={courseSaveLoading}
      />
    </form>
  )

  const {title, description, startTitle, startText} = videoSurvey
  const initialValues = {
    title,
    description,
    startTitle,
    startText,
  }
  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withSortableList(
    InstructionForm,
    ['videoSurvey', 'questions'],
    getInitialVideoScriptQuestion
  )
)
