import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'

import {getAllCompanies, companiesOptionsSelector} from '../../redux/companies'
import {saveCourse} from '../../redux/courses'
import {ReactComponent as DuplicateIcon} from '../../assets/icons/duplicate.svg'
import withDialog from '../decorators/withDialog'
import TextField from '../fields/TextField'
import SelectField from '../fields/SelectField'
import Submit from '../common/Submit'
import CloseButton from '../common/CloseButton'

CourseDuplicate.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  companiesOptions: PropTypes.array,
  allCompaniesLoading: PropTypes.bool.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  saveCourse: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const DialogContent = ({
  id,
  title,
  companyId,
  companiesOptions,
  allCompaniesLoading,
  courseSaveLoading,
  getAllCompanies,
  saveCourse,
  onClose,
}) => {
  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line

  const handleSubmit = (values) => {
    const newCourse = {
      ...values,
      survey: null,
    }
    return saveCourse(newCourse, onClose, id)
  }

  const renderForm = ({handleSubmit}) => (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField required name="title" label="Title" />
      <SelectField
        required
        name="company"
        label="Company"
        loading={allCompaniesLoading}
        options={companiesOptions}
      />
      <Submit loading={courseSaveLoading} />
    </form>
  )

  const initialValues = {
    title,
    company: companiesOptions ? companyId : undefined,
  }
  return (
    <>
      <CloseButton onClick={onClose} disabled={courseSaveLoading} />
      <h2 className="Title Title_indent">Duplicate course</h2>
      <Form
        initialValues={initialValues}
        render={renderForm}
        onSubmit={handleSubmit}
      />
    </>
  )
}

function CourseDuplicate({courseSaveLoading, open, onOpen, onClose, ...props}) {
  return (
    <>
      <Link component="button" onClick={onOpen}>
        <DuplicateIcon className="LinkIcon" />
        Duplicate
      </Link>
      <Dialog open={open} onClose={courseSaveLoading ? undefined : onClose}>
        <DialogContent
          {...props}
          courseSaveLoading={courseSaveLoading}
          onClose={onClose}
        />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  companiesOptions: companiesOptionsSelector(state),
  allCompaniesLoading: state.companies.allCompaniesLoading,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {getAllCompanies, saveCourse}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(CourseDuplicate))
