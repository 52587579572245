import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import MuiLink from '@material-ui/core/Link'

import './CompanyCourses.scss'
import withClipboard from '../decorators/withClipboard'
import LimitChange from './LimitChange'
import {ROUTE, COURSE_STATUS} from '../../utils/constants'

CompanyCourses.proptTypes = {
  courses: PropTypes.array.isRequired,
  setRecipientsFilter: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
}

const recipientsLinkLabel = 'View'

const CompanyCourse = ({
  id,
  title,
  adminStatus,
  code,
  recipients,
  maxRecipients,
  setRecipientsFilter,
  onCopy,
  ...props
}) => {
  const active = adminStatus === COURSE_STATUS.active

  const handleCopy = active
    ? () =>
        onCopy(
          `${process.env.REACT_APP_SURVEY_URL}/${id}/${code}`,
          `Course link`
        )
    : undefined

  const handleFilterSet = () => {
    const filterValue = {
      value: id,
      type: 'Course',
      clear: true,
    }
    setRecipientsFilter(filterValue)
  }

  return (
    <li className="Block Block_simple">
      <div className="CompanyCourses-Wrapper">
        <p className="CompanyCourses-Text">
          <strong>{title}</strong>
        </p>
        {active && (
          <MuiLink component="button" onClick={handleCopy}>
            Copy link
          </MuiLink>
        )}
      </div>
      <div className="CompanyCourses-Wrapper">
        <p className="CompanyCourses-Text CompanyCourses-Text_nowrap">
          {recipients} recipients
        </p>
        {recipients ? (
          <MuiLink
            component={Link}
            to={ROUTE.recipients}
            onClick={handleFilterSet}
          >
            {recipientsLinkLabel}
          </MuiLink>
        ) : (
          <span className="CompanyCourses-Plug">{recipientsLinkLabel}</span>
        )}
      </div>
      <div className="CompanyCourses-Wrapper">
        <p className="CompanyCourses-Text CompanyCourses-Text_nowrap">
          {maxRecipients || 0} limit
        </p>
        <LimitChange
          courseId={id}
          minValue={recipients}
          value={maxRecipients}
        />
      </div>
    </li>
  )
}

function CompanyCourses({courses, setRecipientsFilter, onCopy}) {
  return (
    <>
      <ul className="CompanyCourses">
        {courses.map(({uuid, ...props}) => (
          <CompanyCourse
            key={uuid}
            {...props}
            id={uuid}
            setRecipientsFilter={setRecipientsFilter}
            onCopy={onCopy}
          />
        ))}
      </ul>
    </>
  )
}

export default withClipboard(CompanyCourses)
