import {createSlice} from '@reduxjs/toolkit'

const {reducer, actions} = createSlice({
  name: 'ui',
  initialState: {
    successOpen: false,
    errorOpen: false,
    message: null,
  },
  reducers: {
    openSuccessSnackbar: (state, {payload}) => {
      state.successOpen = true
      state.errorOpen = false
      state.message = payload
    },
    openErrorSnackbar: (state, {payload}) => {
      state.successOpen = false
      state.errorOpen = true
      state.message = payload
    },
    closeSnackbar: (state) => {
      state.successOpen = false
      state.errorOpen = false
    },
  },
})

export const {openSuccessSnackbar, openErrorSnackbar, closeSnackbar} = actions

export default reducer
