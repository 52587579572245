import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import MuiTextField from '@material-ui/core/TextField'

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
}

const digitsRegExp = /^(|\d+)$/

const NumberFieldContent = ({input, meta, onChange, ...props}) => {
  const handleChange = ({target: {value}}) => {
    if (!digitsRegExp.test(value)) return
    input.onChange(value)
  }

  return (
    <MuiTextField
      {...input}
      {...props}
      type="number"
      disabled={meta.submitting}
      onChange={handleChange}
    />
  )
}

export default function NumberField(props) {
  return <Field {...props} component={NumberFieldContent} />
}
