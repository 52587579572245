import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import './CloseButton.scss'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default function CloseButton(props) {
  return (
    <IconButton {...props} className="CloseButton">
      <CloseIcon />
    </IconButton>
  )
}
