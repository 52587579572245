import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'

import './Auth.scss'
import {login} from '../../redux/auth'
import logoBoxSvg from '../../assets/images/logo-box.svg'
import TextField from '../fields/TextField'
import Submit from '../common/Submit'

Auth.propTypes = {
  handleLogin: PropTypes.func.isRequired,
}

function Auth({handleLogin}) {
  const renderForm = ({submitting, handleSubmit}) => (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField required name="username" type="email" label="Login" />
      <TextField required name="password" type="password" label="Password" />
      <Submit fullWidth label="Sign in" loading={submitting} />
    </form>
  )

  return (
    <main className="Auth FadeIn">
      <img
        className="Auth-Logo"
        width={96}
        height={96}
        src={logoBoxSvg}
        alt="Video Profile Mapping"
      />
      <h1 className="Auth-Title Title">Sign in</h1>
      <Form onSubmit={handleLogin} render={renderForm} />
    </main>
  )
}

const mapDispatchToProps = {handleLogin: login}

export default connect(null, mapDispatchToProps)(Auth)
