import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import './AppContent.scss'
import Sidebar from './Sidebar'
import Courses from '../courses/Courses'
import Course from '../courses/Course'

import Recipients from '../recipients/Recipients'
import Companies from '../companies/Companies'
import Company from '../companies/Company'
import {ROUTE} from '../../utils/constants'

export default function AppContent() {
  return (
    <div className="AppContent FadeIn">
      <Sidebar />
      <main className="AppContent-Main">
        <Switch>
          <Route exact path={ROUTE.courses} component={Courses} />
          <Route path={`${ROUTE.courses}/:id`} component={Course} />
          <Route exact path={ROUTE.recipients} component={Recipients} />
          <Route exact path={ROUTE.companies} component={Companies} />
          <Route exact path={`${ROUTE.companies}/:id`} component={Company} />
          <Redirect to={ROUTE.courses} />
        </Switch>
      </main>
    </div>
  )
}
