import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit'
import axios from 'axios'

import auth, {logout} from './auth'
import courses from './courses'
import recipients from './recipients'
import companies from './companies'
import ui from './ui'

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`

const appReducer = combineReducers({
  auth,
  courses,
  recipients,
  companies,
  ui,
})

const reducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined
  }
  return appReducer(state, action)
}

export default configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
