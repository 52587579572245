import React, {useState} from 'react'
import arrayMove from 'array-move'

import {normalizeSortOrder} from '../../utils/helpers'

export default (WrappedComponent, stateProps, getInitialItem) => (props) => {
  const [items, setItems] = useState(
    Array.isArray(stateProps)
      ? props[stateProps[0]][stateProps[1]]
      : props[stateProps]
  )

  const handleSortEnd = ({oldIndex, newIndex}) => {
    const movedItems = normalizeSortOrder(arrayMove(items, oldIndex, newIndex))
    setItems(movedItems)
  }

  const handleItemAdd = () => {
    const newItem = getInitialItem(items.length + 1)
    setItems([...items, newItem])
  }

  const handleItemDelete = (id) => {
    setItems(normalizeSortOrder(items.filter((item) => item.uuid !== id)))
  }

  return (
    <WrappedComponent
      {...props}
      items={items}
      setItems={setItems}
      onSortEnd={handleSortEnd}
      onItemAdd={handleItemAdd}
      onItemDelete={handleItemDelete}
    />
  )
}
