import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'

import {saveCourse} from '../../redux/courses'
import TextField from '../fields/TextField'
import Submit from '../common/Submit'
import {getTicketData} from '../../utils/helpers'

CourseForm.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.string,
  course: PropTypes.object.isRequired,
  courseSaveLoading: PropTypes.bool.isRequired,
  saveCourse: PropTypes.func.isRequired,
}

function CourseForm({type, course, courseSaveLoading, content, saveCourse}) {
  const name = `${type}Content`

  const handleSubmit = (values) => {
    const ticket = getTicketData(course)
    ticket.surveyData[name] = values[name]
    return saveCourse(ticket)
  }

  const renderForm = ({pristine, handleSubmit}) => (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        required
        multiline
        name={name}
        placeholder="Write here…"
        rows={10}
      />
      <Submit pristine={pristine} loading={courseSaveLoading} />
    </form>
  )

  const initialValues = {
    [name]: content,
  }
  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
    />
  )
}

const mapStateToProps = (state) => ({
  course: state.courses.course,
  courseSaveLoading: state.courses.courseSaveLoading,
})

const mapDispatchToProps = {saveCourse}

export default connect(mapStateToProps, mapDispatchToProps)(CourseForm)
