import axios from 'axios'
import qs from 'qs'
import {format, parseISO} from 'date-fns'
import {v4 as generateId} from 'uuid'
import cloneDeep from 'lodash.clonedeep'

import {logout} from '../redux/auth'
import {openErrorSnackbar} from '../redux/ui'
import {TYPE} from './constants'

export const normalizeSortOrder = (items) =>
  items.map((item, index) => ({
    ...item,
    sortOrder: index + 1,
  }))

export const getTicketData = (course) =>
  cloneDeep({
    surveyData: {
      parts: course.parts,
      welcomeContent: course.welcomeContent,
      conclusionContent: course.conclusionContent,
    },
    title: course.title,
    company: course.company.uuid,
    videoSurveyData: course.videoSurvey,
    survey: course.uuid,
  })

export const getInitialStep = (value) => ({
  uuid: generateId(),
  value: value,
  title: `Level ${value}`,
  description: null,
})

export const getInitialSelectboxValue = (value) => ({
  uuid: generateId(),
  value: value,
  title: `Label`,
  description: null,
})

export const getInitialParameter = () => ({
  uuid: generateId(),
  additionalValue: null,
  description: null,
  icon: null,
  value: null,
})

export const getInitialQuestion = (sortOrder) => ({
  sortOrder,
  uuid: generateId(),
  title: sortOrder ? 'Question...' : undefined,
  type: TYPE.text,
  parameters: [{placeholder: sortOrder ? 'Write here…' : undefined}],
})

export const getInitialPage = (sortOrder) => ({
  sortOrder,
  uuid: generateId(),
  title: 'Title...',
  videoPitchLabel: 'none',
  questions: [getInitialQuestion(1)],
})

export const getInitialSection = (sortOrder) => ({
  sortOrder,
  uuid: generateId(),
  title: 'Title...',
  description: 'Description...',
  pages: [getInitialPage(1)],
})

export const getInitialPart = (sortOrder) => ({
  sortOrder,
  uuid: generateId(),
  title: 'Title...',
  subtitle: 'Subtitle...',
  description: 'Description...',
  sections: [getInitialSection(1)],
  thankYouPageTitle: 'Thank you title...',
  thankYouPageContent: '<p>Thank you...</p>',
})

export const getInitialVideoScriptQuestion = (sortOrder) => ({
  sortOrder,
  uuid: generateId(),
  title: 'Title...',
  description: 'Instruction...',
  preDescription: 'Description...',
  examples: ['Example...'],
})

export const getName = (id, ...rest) =>
  `${id}${
    rest.length ? rest.reduce((string, item) => string + `_${item}`, '') : ''
  }`

export const checkOriginal = (items, id) =>
  items ? items.some((item) => item.uuid === id) : false

export const formatDate = (string, pattern = 'dd MMM ‘yy') =>
  format(parseISO(string), pattern)

export const getTableParams = (state, type, filter) => ({
  ...filter,
  paginate: true,
  perPage: state[`${type}Size`],
  page: state[`${type}Page`],
})

const paramsSerializer = (params) => qs.stringify(params)
export const request = {
  cancel(action) {
    action.cancel && action.cancel(true)
  },
  withCancelToken(action, params) {
    return {
      params,
      paramsSerializer,
      cancelToken: new axios.CancelToken((c) => {
        action.cancel = c
      }),
    }
  },
}

export const handleError = (error, dispatch, actionCreator) => {
  if (error.__proto__.__CANCEL__) return
  let errorMessage = 'Server connection error.'
  const {response} = error
  if (response) {
    error.response.status === 401 && dispatch(logout())
    errorMessage = response.data.errors
      ? Object.values(response.data.errors).flat().join('\n')
      : response.data.message || errorMessage
  }
  if (dispatch) {
    actionCreator && dispatch(actionCreator())
    dispatch(openErrorSnackbar(errorMessage))
    return
  }
  alert(errorMessage)
}
