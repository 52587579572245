import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'

import './Notification.scss'
import {closeSnackbar} from '../../redux/ui'

Notification.propTypes = {
  successOpen: PropTypes.bool.isRequired,
  errorOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
}

const autoHideDuration = 8000

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const renderSnackbar = (type, open, message, onClose) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    open={open}
    autoHideDuration={autoHideDuration}
    message={message}
    ContentProps={{
      className: `Notification Notification_type_${type}`,
    }}
    onClose={onClose}
  />
)

export function Notification({successOpen, errorOpen, message, handleClose}) {
  return (
    <>
      {renderSnackbar('success', successOpen, message, handleClose)}
      {renderSnackbar('error', errorOpen, message, handleClose)}
    </>
  )
}

const mapStateToProps = (state) => ({
  successOpen: state.ui.successOpen,
  errorOpen: state.ui.errorOpen,
  message: state.ui.message,
})

const mapDispatchToProps = {
  handleClose: closeSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
