import axios from 'axios'
import {createSlice} from '@reduxjs/toolkit'

import {openSuccessSnackbar} from './ui'
import {getTableParams, request, handleError} from '../utils/helpers'
import {INITIAL_TABLE_SETTINGS} from '../utils/constants'
import FileDownload from 'js-file-download'

const {reducer, actions} = createSlice({
  name: 'companies',
  initialState: {
    companies: null,
    companiesLoading: false,
    companiesSize: INITIAL_TABLE_SETTINGS.size,
    companiesPage: INITIAL_TABLE_SETTINGS.page,
    allCompanies: null,
    allCompaniesLoading: false,
    company: null,
    companyLoading: false,
    companySaveLoading: false,
    companyAddLoading: false,
    recipientsCountChangeLoading: false,
    apiKeysResetLoading: false,
  },
  reducers: {
    getCompaniesStarted: (state) => {
      state.companiesLoading = true
    },
    getCompaniesSuccess: (state, {payload}) => {
      state.companies = payload
      state.companiesLoading = false
    },
    getCompaniesFailed: (state) => {
      state.companiesLoading = false
    },
    setCompaniesSize: (state, {payload}) => {
      state.companiesSize = payload
      state.companiesPage = INITIAL_TABLE_SETTINGS.page
    },
    setCompaniesPage: (state, {payload}) => {
      state.companiesPage = payload
    },
    getAllCompaniesStarted: (state) => {
      state.allCompaniesLoading = true
    },
    getAllCompaniesSuccess: (state, {payload}) => {
      state.allCompanies = payload
      state.allCompaniesLoading = false
    },
    getAllCompaniesFailed: (state) => {
      state.allCompaniesLoading = false
    },
    getCompanyStarted: (state) => {
      state.companyLoading = true
    },
    getCompanySuccess: (state, {payload}) => {
      state.company = payload
      state.companyLoading = false
    },
    getCompanyFailed: (state) => {
      state.companyLoading = false
    },
    saveCompanyStarted: (state) => {
      state.companySaveLoading = true
    },
    saveCompanySuccess: (state, {payload}) => {
      state.company = payload
      state.companySaveLoading = false
    },
    saveCompanyFailed: (state) => {
      state.companySaveLoading = false
    },
    addCompanyStarted: (state) => {
      state.companyAddLoading = true
    },
    addCompanyEnd: (state) => {
      state.companyAddLoading = false
    },
    changeRecipientsCountStarted: (state) => {
      state.recipientsCountChangeLoading = true
    },
    changeRecipientsCountSuccess: (state, {payload}) => {
      state.company.surveys.find(
        (survey) => survey.uuid === payload.id
      ).maxRecipients = payload.maxRecipients
      state.recipientsCountChangeLoading = false
    },
    changeRecipientsCountFailed: (state) => {
      state.recipientsCountChangeLoading = false
    },
    resetCompany: (state) => {
      state.company = null
    },
    resetApiKeysStarted: (state) => {
      state.apiKeysResetLoading = true
    },
    resetApiKeysSuccess: (state, {payload}) => {
      state.company = payload
      state.apiKeysResetLoading = false
    },
    resetApiKeysFailed: (state) => {
      state.apiKeysResetLoading = false
    },
  },
})

const {
  getCompaniesStarted,
  getCompaniesSuccess,
  getCompaniesFailed,
  getAllCompaniesStarted,
  getAllCompaniesSuccess,
  getAllCompaniesFailed,
  getCompanyStarted,
  getCompanySuccess,
  getCompanyFailed,
  saveCompanyStarted,
  saveCompanySuccess,
  addCompanyStarted,
  addCompanyEnd,
  saveCompanyFailed,
  changeRecipientsCountStarted,
  changeRecipientsCountSuccess,
  changeRecipientsCountFailed,
  resetApiKeysStarted,
  resetApiKeysSuccess,
  resetApiKeysFailed,
} = actions

export const {setCompaniesSize, setCompaniesPage, resetCompany} = actions

export const getCompanies = () => async (dispatch, getState) => {
  request.cancel(getCompanies)
  dispatch(getCompaniesStarted())
  const state = getState().companies
  try {
    const {data} = await axios.get(
      '/companyProfile/list',
      request.withCancelToken(getCompanies, getTableParams(state, 'companies'))
    )
    dispatch(getCompaniesSuccess(data))
  } catch (error) {
    handleError(error, dispatch, getCompaniesFailed)
  }
}

export const getAllCompanies = () => async (dispatch) => {
  request.cancel(getAllCompanies)
  dispatch(getAllCompaniesStarted())
  try {
    const {data} = await axios.get(
      '/companyProfile/list',
      request.withCancelToken(getAllCompanies)
    )
    dispatch(getAllCompaniesSuccess(data.data))
  } catch (error) {
    handleError(error, dispatch, getAllCompaniesFailed)
  }
}

export const getCompany = (id) => async (dispatch) => {
  request.cancel(getCompany)
  dispatch(getCompanyStarted())
  try {
    const {data} = await axios.get(
      `/companyProfile/${id}`,
      request.withCancelToken(getCompany)
    )
    dispatch(getCompanySuccess(data.data))
  } catch (error) {
    handleError(error, dispatch, getCompanyFailed)
  }
}

export const saveCompany = ({key, token, documentation, ...values}) => async (
  dispatch,
  getState
) => {
  dispatch(saveCompanyStarted())
  const state = getState().companies
  try {
    const {data} = await axios.post(
      `/companyProfile/${state.company.uuid}`,
      values
    )
    dispatch(saveCompanySuccess(data.data))
    dispatch(openSuccessSnackbar('Company has been updated successfully.'))
  } catch (error) {
    handleError(error, dispatch, saveCompanyFailed)
  }
}

export const addCompany = (values, closeDialog) => async (dispatch) => {
  dispatch(addCompanyStarted())
  try {
    await axios.post(`/companyProfile/new`, values)
    dispatch(getCompanies())
    dispatch(addCompanyEnd())
    dispatch(openSuccessSnackbar('Company has been created successfully.'))
    closeDialog()
  } catch (error) {
    handleError(error, dispatch, addCompanyEnd)
  }
}

export const changeRecipientsCount = (id, values, closeDialog) => async (
  dispatch
) => {
  dispatch(changeRecipientsCountStarted())
  try {
    const {data} = await axios.post(`/survey/${id}/recipients/count`, values)
    dispatch(
      changeRecipientsCountSuccess({id, maxRecipients: data.data.maxRecipients})
    )
    dispatch(
      openSuccessSnackbar('Recipients limit has been changed successfully.')
    )
    closeDialog()
  } catch (error) {
    handleError(error, dispatch, changeRecipientsCountFailed)
  }
}

export const resetApiKeys = (closeDialog) => async (dispatch, getState) => {
  dispatch(resetApiKeysStarted())
  const state = getState().companies
  try {
    const {data} = await axios.get(
      `/companyProfile/${state.company.uuid}/keys/reset`
    )
    dispatch(resetApiKeysSuccess(data.data))
    dispatch(openSuccessSnackbar('API keys have been reset successfully.'))
    closeDialog()
  } catch (error) {
    handleError(error, dispatch, resetApiKeysFailed)
  }
}

export const getReportForCompany = (companyTitle, companyUuid) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/companyProfile/${companyUuid}/report`)
    FileDownload(data, `present-me-report_${companyTitle}.csv`);
  } catch (error) {
    handleError(error, dispatch)
  }
}

export const companiesOptionsSelector = (state) =>
  state.companies.allCompanies
    ? state.companies.allCompanies.map((company) => ({
        value: company.uuid,
        label: company.fullTitle,
      }))
    : null

export default reducer
