import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import MuiTextField from '@material-ui/core/TextField'

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  native: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
}

const parse = (v) => v

const TextFieldContent = ({
  input,
  meta,
  native,
  label,
  placeholder,
  rows,
  multiline,
  onChange,
  ...props
}) => {
  const handleChange = onChange
    ? ({target: {value}}) => {
        input.onChange(value)
        onChange(value)
      }
    : input.onChange

  const Component = native ? 'input' : MuiTextField
  return (
    <Component
      {...input}
      {...props}
      label={native ? undefined : label}
      placeholder={native ? label : placeholder}
      multiline={multiline}
      rows={multiline ? rows : undefined}
      disabled={meta.submitting}
      onChange={handleChange}
    />
  )
}

export default function TextField(props) {
  return <Field {...props} parse={parse} component={TextFieldContent} />
}
