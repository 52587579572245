import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import MuiTextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import CircularProgress from '@material-ui/core/CircularProgress'

import './SelectField.scss'

SelectField.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.array,
  optionsGroup: PropTypes.object,
  onChange: PropTypes.func,
}

const Loader = () => (
  <CircularProgress className="SelectField-Loader" size={22} />
)

const selectProps = {
  IconComponent: Loader,
}

const renderOptions = (options) =>
  options
    ? options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
          {option.description && (
            <span className="ColorGrey TextLight">
              &nbsp;- {option.description}
            </span>
          )}
        </MenuItem>
      ))
    : []

const SelectFieldContent = ({
  input,
  meta,
  loading,
  options,
  optionsGroup,
  onChange,
  ...props
}) => {
  const handleChange = onChange
    ? ({target: {value}}) => {
        input.onChange(value)
        onChange(value)
      }
    : input.onChange

  return (
    <MuiTextField
      {...input}
      {...props}
      select
      SelectProps={loading ? selectProps : undefined}
      disabled={!options || meta.submitting}
      onChange={handleChange}
    >
      {renderOptions(options)}
      {optionsGroup && <ListSubheader>{optionsGroup.title}</ListSubheader>}
      {optionsGroup && renderOptions(optionsGroup.options)}
    </MuiTextField>
  )
}

export default function SelectField(props) {
  return <Field {...props} component={SelectFieldContent} />
}
