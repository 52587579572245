import React from 'react'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'

import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'

AddButton.propTypes = {
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  onClick: PropTypes.func,
}

export default function AddButton({label, small, ...props}) {
  return (
    <Link {...props} component="button" type="button">
      <AddIcon className="LinkIcon" />
      <span className={small ? undefined : 'TextMedium'}>Add {label}</span>
    </Link>
  )
}
