import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import './Company.scss'
import {getCompany, resetCompany, getReportForCompany} from '../../redux/companies'
import {setRecipientsFilter} from '../../redux/recipients'
import Breadcrumbs from '../common/Breadcrumbs'
import Loader from '../common/Loader'
import CompanyCourses from './CompanyCourses'
import CompanyForm from './CompanyForm'
import {formatDate} from '../../utils/helpers'
import {ROUTE} from '../../utils/constants'
import Button from "@material-ui/core/Button"

Company.propTypes = {
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  companyLoading: PropTypes.bool.isRequired,
  getCompany: PropTypes.func.isRequired,
  resetCompany: PropTypes.func.isRequired,
  setRecipientsFilter: PropTypes.func.isRequired,
}

const links = [{to: ROUTE.companies, label: 'Companies'}]

function Company({
  match,
  company,
  companyLoading,
  getCompany,
  resetCompany,
  setRecipientsFilter,
  getReportForCompany,
}) {

  const handleReport = () => {
    if (company) {
      getReportForCompany(company.title, company.uuid)
    }
  }

  useEffect(() => {
    const {id} = match.params
    if (company && id !== company.uuid) resetCompany()
    getCompany(id)
  }, []) // eslint-disable-line

  return (
    <section className="Company">
      <div className="Company-Panel">
        <Breadcrumbs noMargin links={links} title={company?.fullTitle} />
        {company && (
          <p className="ColorGrey">
            Registered{' '}
            <time>{formatDate(company.createdAt, 'dd MMMM yyyy')}</time>
          </p>
        )}
      </div>
      {company ? (
        <div className="Relative">
          <Loader loading={companyLoading} type="block" />
          <div className="Company-Title">
            <h1 className="Title Title_indent">{company.fullTitle}</h1>
            <div>
              <Button onClick={handleReport}>Export recipients</Button>
            </div>
          </div>
          <CompanyCourses
            courses={company.surveys}
            setRecipientsFilter={setRecipientsFilter}
          />
          <CompanyForm company={company} />
        </div>
      ) : (
        <Loader />
      )}
    </section>
  )
}

const mapStateToProps = (state) => ({
  company: state.companies.company,
  companyLoading: state.companies.companyLoading,
})

const mapDispatchToProps = {
  getCompany,
  resetCompany,
  setRecipientsFilter,
  getReportForCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(Company)
