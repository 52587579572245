import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import './CheckboxField.scss'
import CheckboxIcon from '../common/CheckboxIcon'

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const CheckboxFieldContent = ({input, label, meta}) => {
  return (
    <div className="CheckboxField">
      <FormControlLabel
        name={input.name}
        label={label}
        control={
          <Checkbox
            checked={input.checked}
            icon={<CheckboxIcon disabled={meta.submitting} />}
            checkedIcon={<CheckboxIcon checked disabled={meta.submitting} />}
          />
        }
        disabled={meta.submitting}
        onChange={input.onChange}
      />
    </div>
  )
}
export default function CheckboxField(props) {
  return <Field {...props} type="checkbox" component={CheckboxFieldContent} />
}
