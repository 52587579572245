import React from 'react'
import {SortableHandle} from 'react-sortable-hoc'

import './DragElement.scss'
import {ReactComponent as DragIcon} from '../../assets/icons/drag.svg'

function DragElement() {
  return (
    <div className="DragElement" aria-label="Drag">
      <DragIcon />
    </div>
  )
}

export default SortableHandle(DragElement)
