import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import './LoaderButton.scss'

LoaderButton.propTypes = {
  className: PropTypes.string,
  pristine: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
}

export default function LoaderButton({
  className,
  pristine,
  loading,
  label,
  ...props
}) {
  return (
    <Button
      {...props}
      className={cn('LoaderButton', {[className]: className})}
      disabled={pristine || loading}
    >
      {label}
      {loading && (
        <CircularProgress size={24} className="LoaderButton-Progress" />
      )}
    </Button>
  )
}
